import { Prescription, PrescriptionSenderCompany, PrescriptionSenderUser } from 'api/prescriptions/types'
import { PublicUserProfile } from 'api/profile/types'
import { ProjectMember } from 'api/references/projectMembers/types'
import { CustomColumnDef } from 'components/Table/Table.types'
import { EliminationStatus } from 'core/types/elimination'
import { AuditMails, FullPeriod, LegendColors, Media } from 'core/types/global'
import { FilterRange, SortingOrder, filterableColumns } from 'core/types/prescription'
import { RemarkFilterableColumn, SortableColumn } from 'core/types/remarks'
import { UserRoles } from 'core/types/user'
import {
  assignmentTypeColumn,
  authorColumn,
  basisColumn,
  commentColumn,
  contractorColumn,
  contractorStatusColumn,
  dateDoneColumn,
  dateEndColumn,
  dateStartColumn,
  descriptionColumn,
  typeColumn,
  measuresColumn,
  numberColumn,
  objectColumn,
  prescriptionDateStartColumn,
  prescriptionNumberColumn,
  responsibleColumn,
  senderCompanyColumn,
  senderUserColumn,
} from 'pages/Remarks/components/RemarksTable/RemarksTable.types'
import {
  REMARK_COMPLETE_STATUS_COLOR,
  REMARK_CREATED_STATUS_COLOR,
  REMARK_DISCARDED_STATUS_COLOR,
  REMARK_SENT_STATUS_COLOR,
} from 'utils/constants'

export type RemarkCopyShort = Pick<RemarkShort, 'status' | 'responsible' | 'period' | 'comment'>

export interface RemarkShort {
  assignmentType: number
  measures: RemarkShortMeasures
  basis: string
  senderCompany: RemarkShortSenderCompany
  senderUser: RemarkShortSenderUser
  comment: string
  contractor: RemarkShortContractor
  description: RemarkShortDescription
  objectId: number
  period: RemarkShortPeriod
  responsible: RemarkShortResponsible | null
  status: RemarkStatuses
  mediaCommentById: RemarkMediaComments | null
  notificationsEnabled?: boolean
}

export interface RemarkSecondaryShort {
  period: RemarkShortPeriod
  responsible: RemarkShortResponsible | null
  comment: string | null
}

export interface RemarkFull {
  id: number
  primary: number | null
  number: string
  comment: string
  description: RemarkFullDescription
  author: RemarkAuthor
  measures: RemarkFullMeasures
  basis: string
  assignmentType: RemarkFullAssignmentType
  contractor: RemarkFullContractor
  copyNumber: number | null
  object: RemarkFullObject
  period: FullPeriod
  prescription: Prescription | null
  responsible: RemarkFullResponsible
  media: Media[]
  status: RemarkStatuses
  contractorStatus: EliminationStatus
  notificationsEnabled: boolean
  allCopiesExpired: boolean
  wasSent: boolean
  latestDateEnd: string | null
  previousDateEnd: string | null
  hasSecondary: boolean
  hasSecondaryInPrescription: boolean
  senderCompany: PrescriptionSenderCompany
  senderUser: PrescriptionSenderUser
  count: IRemarkCount
}

export interface RemarkFullDescription {
  custom: string | null
  defect: RemarkFullDefect | null
}

export interface RemarkFullDefect {
  code: string
  id: number
  note: string
  title: string
  type: string
}

export interface RemarkAuthor
  extends Pick<PublicUserProfile, 'id' | 'firstName' | 'lastName' | 'middleName' | 'phone'> {}

export interface RemarkShortMeasures {
  custom: string | null
  nd: number | null
  rd: number | null
}

export interface RemarkFullMeasures {
  custom: string | null
  nd: RemarkFullMeasuresData | null
  rd: RemarkFullMeasuresData | null
}

export interface RemarkFullMeasuresData {
  id: number
  name: string
}

export interface RemarkShortSenderCompany {
  company: string | null
  projectMember: number | null
}

export interface RemarkShortSenderUser {
  engineerQC: number | null
  representative: number | null
}

export interface RemarkShortContractor {
  company: string | null
  contractor: number | null
}

export interface RemarkShortDescription {
  custom: string | null
  defect: number | null
}

export interface RemarkShortResponsible {
  representative: number | null
  user: number | null
}

export interface RemarkShortPeriod {
  dateDone: string | null
  dateEnd: string
  dateStart: string
}

export interface RemarkFullAssignmentType {
  description: string
  id: number
  note: string
  title: string
}

export interface RemarkFullContractor {
  company: string
  contractor: RemarkFullContractorData
}

export interface RemarkFullContractorData {
  fullName: string
  id: number
  note: string
  shortName: string
}

export interface RemarkFullObject {
  description: string
  id: number
  number: string
  project: number
  title: string
}

export interface RemarkFullResponsible {
  representative: RemarkRepresentative
  user: RemarkFullResponsibleUser
}

export interface RemarkRepresentative {
  fullName: string
  id: number
  note: string
  position: string
}

export interface RemarkFullResponsibleUser {
  firstName: string
  id: number
  lastName: string
  middleName: string
  role: UserRoles
  position: string
}

export interface RemarkElimination {
  status: EliminationStatus
  contractorReason: string | null
  engineerReason: string | null
  responsible: RemarkEliminationResponsible
  period: EliminationPeriod
  measures: string
  media: Media[]
}

export interface EliminationShort {
  responsible: number | null
  contractorReason: string | null
  engineerReason: string | null
  mediaCommentById: RemarkMediaComments | null
  measures: string | null
  period: EliminationPeriod
}

export interface EliminationPeriod {
  dateDone: string | null
}

export interface RemarkEliminationResponsible {
  id: number
  fullName: string
  position: string
  note: string
  email: string | null
  contractor: any
}

export interface RemarkMediaComments extends Record<string, string> {}

export interface RemarkAudit {
  action: RemarkAuditAction
  prescription: string | null
  previous: RemarkStatuses
  value: RemarkStatuses
  timestamp: string
  user: RemarkAuditUser
  contractorReason: string | null
  contractorStatus: EliminationStatus
  previousContractorStatus: EliminationStatus
  qcReason: string | null
  mailRecipients: AuditMails[] | null
  responsible: ProjectMember | null
  shortcoming: string | null
  previousResponsible: ProjectMember | null
}

export interface RemarkSecondaryAudit {
  action: RemarkSecondaryAuditAction
  timestamp: string
  user: RemarkAuditUser
  prescription: string | null
  copyNumber: RemarkFull['copyNumber']
  dateEnd: string
}

export type RemarkAuditAction = 'CREATE' | 'STATUS' | 'CONTRACTOR_STATUS' | 'CONTRACTOR_RESPONSIBLE'

export type RemarkSecondaryAuditAction = 'CREATE'

export interface RemarkAuditUser {
  role: UserRoles
  user: RemarkFullResponsibleUser
}

export type RemarkStatuses = 'CREATED' | 'SENT' | 'COMPLETE' | 'DISCARDED'
export type RemarkStatusesRu = 'Создано' | 'Направлено' | 'Исполнено' | 'Снято'

export const remarkStatuses: RemarkStatusesRu[] = ['Создано', 'Исполнено', 'Снято']

export const remarkStatusesColor: Record<RemarkStatuses, LegendColors> = {
  CREATED: REMARK_CREATED_STATUS_COLOR,
  SENT: REMARK_SENT_STATUS_COLOR,
  COMPLETE: REMARK_COMPLETE_STATUS_COLOR,
  DISCARDED: REMARK_DISCARDED_STATUS_COLOR,
}

export enum remarkStatusesBackend {
  'Создано' = 'CREATED',
  'Направлено' = 'SENT',
  'Исполнено' = 'COMPLETE',
  'Снято' = 'DISCARDED',
}

export enum remarkStatusesClient {
  'CREATED' = 'Создано',
  'SENT' = 'Направлено',
  'COMPLETE' = 'Исполнено',
  'DISCARDED' = 'Снято',
}

export interface IRemarkCount {
  media: number | null
  contractorMedia: number | null
}

export interface CurrentSortableColumn {
  column: SortableColumn
  order: SortingOrder
}

export interface RemarksFilters extends Record<RemarkFilterableColumn, string[] | number[] | null> {
  number: string[] | null
  status: string[] | null
  sender_company: string[] | null
  sender_user: string[] | null
  period_dateStart: string[] | null
  period_dateStart_range: FilterRange | null
  period_dateEnd: string[] | null
  period_dateEnd_range: FilterRange | null
  period_dateDone: string[] | null
  period_dateDone_range: FilterRange | null
  contractor: string[] | null
  contractorStatus: string[] | null
  prescription: string[] | null
  type: string[] | null
  assignment_type: string[] | null
  author: string[] | null
  responsible: string[] | null
  object: number[] | null
}

// COLUMNS
export type RemarkColumn =
  | 'NUMBER'
  | 'STATUS'
  | 'OBJECT'
  | 'DESCRIPTION'
  | 'DATE_START'
  | 'AUTHOR'
  | 'DATE_END'
  | 'DATE_DONE'
  | 'SENDER_COMPANY'
  | 'SENDER_USER'
  | 'CONTRACTOR_STATUS'
  | 'CONTRACTOR'
  | 'PRESCRIPTION_NUMBER'
  | 'ASSIGNMENT_TYPE'
  | 'MEASURES'
  | 'BASIS'
  | 'COMMENT'
  | 'RESPONSIBLE'
  | 'BASIS'
  | 'PRESCRIPTION_DATE_START'
  | 'TYPE'

export const remarkDefaultColumns: RemarkColumn[] = [
  'NUMBER',
  'STATUS',
  'OBJECT',
  'DESCRIPTION',
  'DATE_START',
  'AUTHOR',
  'SENDER_COMPANY',
  'DATE_END',
  'DATE_DONE',
  'CONTRACTOR_STATUS',
  'CONTRACTOR',
  'PRESCRIPTION_NUMBER',
]

export const remarkColumns: RemarkColumn[] = [
  'NUMBER',
  'STATUS',
  'TYPE',
  'OBJECT',
  'DESCRIPTION',
  'DATE_START',
  'AUTHOR',
  'DATE_END',
  'DATE_DONE',
  'SENDER_COMPANY',
  'SENDER_USER',
  'CONTRACTOR_STATUS',
  'CONTRACTOR',
  'PRESCRIPTION_NUMBER',
  'ASSIGNMENT_TYPE',
  'MEASURES',
  'BASIS',
  'COMMENT',
  'RESPONSIBLE',
  'PRESCRIPTION_DATE_START',
]

export const remarkColumnNameByValue: Record<RemarkColumn, string> = {
  NUMBER: '№',
  STATUS: 'Статус',
  OBJECT: 'Объект строительства',
  DESCRIPTION: 'Описание',
  DATE_START: 'Дата замечания',
  AUTHOR: 'Автор',
  DATE_END: 'Уст. дата устранения',
  DATE_DONE: 'Факт. дата устранения',
  SENDER_COMPANY: 'Организация, выдавшая замечание',
  SENDER_USER: 'Замечание составил',
  CONTRACTOR_STATUS: 'Статус устранения',
  CONTRACTOR: 'Организация, получившая замечание',
  PRESCRIPTION_NUMBER: 'Номер предписания',
  ASSIGNMENT_TYPE: 'Вид работ',
  MEASURES: 'Мероприятия по устранению',
  BASIS: 'Основание',
  COMMENT: 'Комментарии',
  RESPONSIBLE: 'Ответственный',
  PRESCRIPTION_DATE_START: 'Дата предписания',
  TYPE: 'Тип замечания',
}

export const remarkColumnByValue: Record<Exclude<RemarkColumn, 'STATUS'>, CustomColumnDef<RemarkFull>> = {
  NUMBER: numberColumn,
  OBJECT: objectColumn,
  DESCRIPTION: descriptionColumn,
  DATE_START: dateStartColumn,
  AUTHOR: authorColumn,
  DATE_END: dateEndColumn,
  DATE_DONE: dateDoneColumn,
  SENDER_COMPANY: senderCompanyColumn,
  SENDER_USER: senderUserColumn,
  CONTRACTOR_STATUS: contractorStatusColumn,
  CONTRACTOR: contractorColumn,
  PRESCRIPTION_NUMBER: prescriptionNumberColumn,
  TYPE: typeColumn,
  ASSIGNMENT_TYPE: assignmentTypeColumn,
  MEASURES: measuresColumn,
  BASIS: basisColumn,
  COMMENT: commentColumn,
  RESPONSIBLE: responsibleColumn,
  PRESCRIPTION_DATE_START: prescriptionDateStartColumn,
}

// GLOBAL COLUMNS
export type RemarkGlobalColumn = 'showSecondary'

export const remarkGlobalColumnNameByValue: Record<RemarkGlobalColumn, string> = {
  showSecondary: 'Повторные замечания',
}
