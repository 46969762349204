import { Stack } from '@mui/material'
import { useBreadcrumbProjectLink } from 'hooks/useBreadcrumbProjectLink'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { RemarksLegend } from 'pages/Remarks/components/RemarksLegend'
import { FC, useContext, useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { CallTable } from './components/CallTable'
import { uiContext } from 'contexts/ui'

export const Calls: FC = () => {
  const { role } = useTypedSelector(profileSelector)
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const accessToAdd = role === 'admin' || role == 'engineer_qc' || role === 'contractor'
  const cUI = useContext(uiContext)

  const { onProjectBreadClick, project, isFetching, ProjectsMenu, isMenuOpen } = useBreadcrumbProjectLink({
    projectId,
    navigateUrl: '/project/{projectId}/calls',
  })

  useBreadcrumbs(
    [
      { title: 'Проекты' },
      {
        title: project?.shortName!,
        onClick: (e) => onProjectBreadClick(e),
        MenuOpen: isMenuOpen,
        type: 'menu',
      },
      { title: 'Вызовы' },
    ],
    [isMenuOpen, project],
    isFetching,
  )

  useLayoutEffect(() => {
    cUI.setShowSearch(false)
    return () => {
      cUI.setShowSearch(true)
    }
  }, [])

  return (
    <Stack flex={1} maxWidth='100%'>
      <RemarksLegend
        accessToAdd={accessToAdd}
        createButtonData={{
          text: 'Создать вызов',
          href: `/project/${projectId}/calls/add`,
        }}
        managmentButton={false}
      />

      <CallTable />

      {ProjectsMenu}
    </Stack>
  )
}
