import { IconButton, Menu, Stack, TextField, Typography } from '@mui/material'
import { Button } from 'components/Button'
import { CustomSelect } from 'components/CustomSelect'
import { Form, FormikProvider } from 'formik'
import { useForm } from 'hooks/useForm'
import { FormButtonWrapper } from 'pages/Regulations/RegulationsDrawers/DocumentManagment/DocumentManagmentForm/DocumentManagmentForm.styles'
import { FieldItem } from 'pages/Remarks/components/RemarkForm/components/FieldItem'
import { FC, useCallback, useState, MouseEvent, useMemo, useEffect } from 'react'
import {
  Person as PersonIcon,
  Smartphone as PhoneIcon,
  EventNote as CalendarIconPlan,
  EventAvailable as CalendarIconFact,
  KeyboardArrowDown as ArrowIcon,
  Chat as ChatIcon,
} from '@mui/icons-material'
import { StyledPhoneFieldForm } from 'pages/Administration/components/ProjectIntegrationDrawer/components/IntegrationRequestSlide/IntegrationRequestSlide.styles'
import { FieldForm } from 'components/FieldForm'
import { EliminationStatusMarker } from 'pages/Remarks/components/EliminationDrawer/components/EliminationForm/EliminationForm.styles'
import {
  inspectionStatusColorByEn,
  inspectionStatuses,
  inspectionStatusRuByEn,
  TInspectionStatus,
} from 'core/types/call'
import { StatusWrapper } from './InspectionForm.styles'
import { StyledSelectMenuItem } from 'components/UserManagement/components/UserRoleSelection'
import { getInitialResponsible, IInspectionFormData, IInspectionFormProps } from './InspectionForm.types'
import { inspectionValidationSchema } from './InspectionForm.validation'
import { useEditInspectionMutation, useGetDropdownInspectionResponsibleQuery } from 'api/calls'
import { IInspectionShort } from 'api/calls/types'
import { useParams } from 'react-router-dom'
import { CustomSelectValueWithType } from 'pages/Remarks/components/RemarkForm/RemarkForm.types'
import { GetDropdownResponsibleUsersData } from 'api/calls/api.types'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { getInspectionStatusData } from 'pages/Calls/components/CallForm/components/Inspection/Inspection.types'
import { formatDateForServer } from 'utils/dates/formatDateForServer'
import { parseResponseDate } from 'utils/dates/parseResponseDate'
import { connectNames } from 'utils/connectNames'
import { TimePicker } from '@mui/x-date-pickers'
import { formatTimeForServer } from 'utils/dates/formatTimeForServer'
import { parseResponseDateTime } from 'utils/dates/parseResponseDateTime'
import { parseResponseTime } from 'utils/dates/parseResponseTime'

export const InspectionForm: FC<IInspectionFormProps> = ({ callData, onFormChange, onClose }) => {
  const { projectId: projectIdString, callId: callIdString } = useParams()
  const projectId = Number(projectIdString)
  const callId = Number(callIdString)
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const [editInspection, { isLoading: isEditing, ...editInspectionResponse }] = useEditInspectionMutation()
  const { enqueueSnackbar } = useSnackbar()

  const { data: inspectionResponsibleDropdown } = useGetDropdownInspectionResponsibleQuery({ projectId })

  const { status, responsible, phone, planDate, planTime, factDate, description } = callData?.inspection || {}

  const parsedCreatedDate = parseResponseDate(callData?.createdDate).date
  const parsedPlanDate = planDate ? parseResponseDate(planDate).date : null
  const parsedPlanTime = parseResponseTime(planTime).date
  const parsedFactDate = factDate ? parseResponseDate(factDate).date : null

  const initialValues = useMemo(() => {
    const data: IInspectionFormData = {
      callCreatedDate: parsedCreatedDate,
      status: status || null,
      responsible: getInitialResponsible(responsible),
      phone: phone || '',
      planDate: parsedPlanDate,
      planTime: parsedPlanTime,
      factDate: parsedFactDate,
      description: description || null,
    }

    return data
  }, [])

  const onSubmit = useCallback((values: IInspectionFormData) => {
    const { status, responsible, phone, planDate, planTime, factDate, description } = values

    const dataForRequest: IInspectionShort = {
      status: status!,
      responsible: {
        engineerQC: responsible?.type?.role === 'user' ? Number(responsible?.id) : null,
        representative: responsible?.type?.role === 'representative' ? Number(responsible?.id) : null,
      },
      phone,
      planDate: formatDateForServer(planDate) || null,
      planTime: formatTimeForServer(planTime) || null,
      factDate: formatDateForServer(factDate) || null,
      description: description || null,
    }

    editInspection({ projectId, callId, body: dataForRequest })
  }, [])

  const { formik } = useForm({
    validationSchema: inspectionValidationSchema,
    enableReinitialize: true,
    initialValues,
    onSubmit,
  })

  const { values, setFieldValue, dirty, isValid, setValues, setTouched } = formik

  const inspectionStatusData = getInspectionStatusData(values.status)

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  const onStatusClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setMenuAnchor(e.currentTarget)
  }, [])

  const onMenuClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }, [])

  const onMenuItemClick = useCallback(
    (status: TInspectionStatus) => {
      setMenuAnchor(null)

      const shouldResetDates = status === 'REJECTED' || status === 'PLANNING'

      setValues({
        ...values,
        status,
        planDate: shouldResetDates ? null : values.planDate,
        planTime: shouldResetDates ? null : values.planTime,
        factDate: shouldResetDates ? null : values.factDate,
        description: '',
      })
    },
    [values],
  )

  const onMenuClose = useCallback(() => {
    setMenuAnchor(null)
  }, [])

  const handleResponsibleSelection = (data: CustomSelectValueWithType, root: GetDropdownResponsibleUsersData) => {
    setFieldValue('phone', root?.data.phone || '')
  }

  useMutationHandlers(
    editInspectionResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }

      onClose(true)
      enqueueSnackbar('Данные успешно изменены.', { variant: 'success' })
    },
    () => {
      enqueueSnackbar('Произошла ошибка', { variant: 'error' })
    },
  )

  return (
    <Stack height='100%' overflow='auto'>
      <FormikProvider value={formik}>
        <Stack component={Form} height='100%' justifyContent='space-between'>
          <Stack spacing={2.5} height='100%'>
            <Stack height='100%' p={2.5} spacing={2.5} overflow='auto'>
              <FieldItem title='Статус осмотра' isRequired>
                <StatusWrapper onClick={onStatusClick} isReadOnly={false} width={205}>
                  <Stack direction='row' alignItems='center'>
                    <EliminationStatusMarker markerColor={inspectionStatusData.color} />
                    <Typography variant='body2' lineHeight='100%'>
                      {inspectionStatusData.body}
                    </Typography>
                  </Stack>

                  <IconButton>
                    <ArrowIcon fontSize='small' />
                  </IconButton>
                </StatusWrapper>

                <Menu
                  anchorEl={menuAnchor}
                  open={!!menuAnchor}
                  onClick={onMenuClick}
                  onClose={onMenuClose}
                  PaperProps={{ style: { width: '207px' } }}
                >
                  {inspectionStatuses
                    .filter((inspectionStatus) => inspectionStatus !== values.status)
                    .map((inspectionStatus) => {
                      const checked = values.status === inspectionStatus
                      const value = inspectionStatusRuByEn[inspectionStatus]

                      return (
                        <StyledSelectMenuItem
                          onClick={() => onMenuItemClick(inspectionStatus)}
                          style={{ width: '100%', maxWidth: '100%' }}
                          value={inspectionStatus}
                          checked={checked}
                          key={value}
                        >
                          <EliminationStatusMarker markerColor={inspectionStatusColorByEn[inspectionStatus]} />
                          {value}
                        </StyledSelectMenuItem>
                      )
                    })}
                </Menu>
              </FieldItem>

              <Stack spacing={1.25}>
                <CustomSelect
                  name='responsible'
                  list={
                    inspectionResponsibleDropdown?.data?.map((item) => ({
                      ...item,
                      group:
                        item.data.role === 'representative' ? 'Представители участников проекта:' : 'Пользователи:',
                    })) || []
                  }
                  isSearch={true}
                  placeholder='Выберите из списка'
                  isSubtext={true}
                  // isDisabled={!values?.responsibleCompany?.value}
                  label='Ответственный за осмотр'
                  style={{
                    textAlign: 'start',
                  }}
                  // readOnly={readOnly}
                  isRequired={true}
                  icon={<PersonIcon fontSize='medium' color='secondary' />}
                  handleValueSelection={handleResponsibleSelection}
                  isGroup={true}
                />

                <FieldItem
                  title='Телефон ответственного'
                  icon={<PhoneIcon fontSize='medium' color='secondary' />}
                  isRequired={true}
                  direction='column'
                  spacing={0.5}
                >
                  <StyledPhoneFieldForm
                    name='phone'
                    placeholder='Укажите номер телефона'
                    helperText=''
                    // inputProps={{
                    //   readOnly,
                    // }}
                  />
                </FieldItem>
              </Stack>

              {values.status !== 'REJECTED' && (
                <Stack spacing={1.25}>
                  <Stack direction='row' spacing={1}>
                    <FieldItem
                      title='Планируемая дата осмотра'
                      icon={<CalendarIconPlan fontSize='medium' color='secondary' />}
                      isRequired={values.status === 'PLANNED'}
                    >
                      <FieldForm
                        version='date'
                        name='planDate'
                        placeholder='дд.мм.гггг'
                        helperText=''
                        dataValue={values.planDate}
                        onDataChange={(value: Date | null) => setFieldValue('planDate', value)}
                        style={{ maxWidth: '120px', width: '100%' }}
                        dateFieldProps={{
                          minDate: values.callCreatedDate || undefined,
                          readOnly: values.status === 'PLANNING',
                        }}
                      />
                    </FieldItem>

                    <FieldForm
                      version='time'
                      name='planTime'
                      helperText=''
                      timeValue={values.planTime}
                      onTimeChange={(value: Date | null) => setFieldValue('planTime', value)}
                      style={{ maxWidth: '95px', width: '100%' }}
                      disabled={values.status === 'PLANNING'}
                    />
                  </Stack>

                  <FieldItem
                    title='Фактическая дата осмотра'
                    icon={<CalendarIconFact fontSize='medium' color='secondary' />}
                    isRequired={values.status === 'COMPLETED'}
                  >
                    <FieldForm
                      version='date'
                      name='factDate'
                      placeholder='дд.мм.гггг'
                      helperText=''
                      dataValue={values.factDate}
                      onDataChange={(value: Date | null) => setFieldValue('factDate', value)}
                      style={{ maxWidth: '144px', width: '100%' }}
                      dateFieldProps={{
                        minDate: values.callCreatedDate || undefined,
                        maxDate: new Date() || undefined,
                        readOnly: values.status !== 'COMPLETED',
                      }}
                    />
                  </FieldItem>
                </Stack>
              )}

              {(values.status === 'COMPLETED' || values.status === 'REJECTED') && (
                <FieldItem
                  title={values.status === 'COMPLETED' ? 'Результат осмотра' : 'Обоснование'}
                  icon={<ChatIcon fontSize='medium' color='secondary' />}
                  direction='column'
                  isRequired
                >
                  <>
                    <FieldForm
                      version='project'
                      name='description'
                      placeholder={values.status === 'COMPLETED' ? 'Укажите результат осмотра' : 'Укажите обоснование'}
                      multiline
                      helperText=''
                      inputProps={{
                        // readOnly,
                        style: {
                          minHeight: '140px',
                        },
                      }}
                    />
                  </>
                </FieldItem>
              )}
            </Stack>

            <FormButtonWrapper padding={2.5} spacing={1}>
              <Stack direction='row' spacing={2} width='100%'>
                <Button
                  type='submit'
                  disabled={!dirty || !isValid}
                  loading={isEditing}
                  color='success'
                  size='medium'
                  fullWidth
                >
                  Сохранить
                </Button>
                <Button size='medium' fullWidth onClick={() => onClose()}>
                  Закрыть
                </Button>
              </Stack>
            </FormButtonWrapper>
          </Stack>
        </Stack>
      </FormikProvider>
    </Stack>
  )
}
