import { Stack } from '@mui/material'
import { Button } from 'components/Button'
import styled from 'styled-components'

export const NotificationSwitchWrapper = styled(Stack)`
  background-color: ${(props) => props.theme.palette.bg.shades};
  padding: 2px 10px 10px 10px;
  gap: 6px;
  border-radius: 8px;
`
