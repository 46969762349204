import { Box, Divider, Stack, Typography } from '@mui/material'
import { TanglIntegrationInfoCard } from './SettingsInfo.styles'
import { IasLogoIcon } from 'assets/icons/IasLogoIcon'

export const SettingsInfo = () => {
  return (
    <Stack width='50%' alignItems='center'>
      <TanglIntegrationInfoCard>
        <Box>
          <IasLogoIcon style={{ height: '140px', width: '250px' }} />
        </Box>

        <Stack spacing={2.5}>
          <Stack>
            <Typography variant='body2' fontWeight={300} fontSize={22}>
              Интеграция с ИАС «Мониторинг»
            </Typography>
            <Divider />
          </Stack>
          <Typography variant='body2' textAlign='start'>
            Интеграция обеспечивает возможность получения и просмотра предписаний, оформленных в ИАС «Мониторинг».
          </Typography>
          <Typography variant='body2' textAlign='start'>
            Для настройки интеграции нужно указать валидные данные идентификаторов корпоративной учетной записи вашей
            компании в ИАС «Мониторинг».
          </Typography>
        </Stack>
      </TanglIntegrationInfoCard>
    </Stack>
  )
}
