import {
  RemarkFullAssignmentType,
  RemarkFullContractor,
  RemarkFullDescription,
  RemarkFullObject,
  RemarkFullResponsible,
  RemarkMediaComments,
  RemarkShort,
  RemarkStatuses,
  RemarksFilters,
} from 'api/remarks/types'
import { CustomSelectValue } from 'components/CustomSelect/CustomSelect.types'
import { FileWithId, Media } from 'core/types/global'
import { PrescriptionFormData } from 'pages/Prescriptions/components/PrescriptionsForm/PrescriptionsForm.types'
import { CurrentSortableColumn } from 'pages/Prescriptions/components/PrescriptionsTable'
import { connectNames } from 'utils/connectNames'
import { formatDateForServer } from 'utils/dates/formatDateForServer'

export interface RemarkFormData {
  status: RemarkStatuses
  dateStart: Date | null
  number: string
  senderUser: CustomSelectValueWithType | null
  senderCompany: CustomSelectValueWithType | null
  object: CustomSelectValue | null
  activityType: CustomSelectValue | null
  measures: string
  basis: string
  description: CustomSelectValueWithType | null
  comments: string

  contractor: CustomSelectValueWithType | null
  responsible: CustomSelectValueWithType | null
  dateEnd: Date | null
  dateDone: Date | null
  prescriptionNumber: string
  prescriptionDate: string
  notificationsEnabled: boolean
  copyNumber: number | null
  media: Media[]
  mediaIdsToDelete: number[]
  mediaForCreate: FileWithId[]
  mediaCommentById: null | RemarkMediaComments
  newMediaCommentById: null | RemarkMediaComments
  previousDateEnd: Date | null
}

export interface RemarkLocationState {
  sortableColumn: CurrentSortableColumn
  filterData: RemarksFilters
  prescriptionValues?: PrescriptionValuesWithId
  currentPage?: number
  prescriptionsPage?: number
}

export interface PrescriptionValuesWithId extends PrescriptionFormData {
  id: number
}

export interface CustomSelectValueWithType extends CustomSelectValue {
  type: string
}

export type RemarkDrawerType = 'elimination' | 'remarkSecondary' | 'audit'

export type RemarkFormDialogTrigger =
  | 'deletePrimary'
  | 'deleteSecondary'
  | 'exit'
  | 'submit'
  | 'eliminationExit'
  | 'notification'
  | 'remarkSecondaryExit'
  | 'autofillDescription'
  | 'autofillMeasuresAndBasis'

export interface RemarkAutofilledData {
  object: CustomSelectValue | null
  contractor: CustomSelectValueWithType | null
}

export const remarkModalNotifyInfo = 'Уведомление о смене статуса будет направлено на e-mail представителей подрядчика.'
export const remarkNotificationInfo =
  'Уведомления о смене статуса устранения будут направлены на e-mail автора предписания. Если  уведомления о смене статуса устранения по данному замечанию не нужны, переведите переключатель в неактивное положение.'
export const remarkSecondaryInfo =
  'В связи с неустранением направленного замечания в  установленный срок доступно создание повторного замечания для направления в адрес подрядчика в составе нового предписания.'

export const getInitialContractor = (
  contractor?: RemarkFullContractor,
  prescriptionValues?: PrescriptionValuesWithId,
  itemId?: number,
) => {
  let data = null

  if (!prescriptionValues?.contractor && !contractor?.contractor && !contractor?.company) return data

  if (itemId) {
    data = {
      id: contractor?.contractor?.id || contractor?.company,
      value: contractor?.contractor?.shortName || contractor?.company,
      type: (contractor?.contractor?.id && 'projectMember') || (contractor?.company && 'company') || 'custom',
    }

    return data as CustomSelectValueWithType
  }

  data = {
    id: prescriptionValues?.contractor?.id || 0,
    value: prescriptionValues?.contractor?.value || '',
    type: (prescriptionValues?.contractor?.id && 'projectMember') || 'custom',
  }

  return data
}

export const getInitialResponsible = (responsible?: RemarkFullResponsible) => {
  let data = null

  if (!responsible?.representative && !responsible?.user) return data

  data = {
    id: responsible?.representative?.id || responsible?.user?.id,
    value: responsible?.representative?.fullName || connectNames(responsible?.user) || '',
    type: (responsible?.representative?.id && 'representative') || (responsible?.user?.id && 'user') || 'custom',
    subtext: responsible?.representative?.position || responsible?.user?.position || '',
  }

  return data
}

export const getInitialSelectData = (info?: RemarkFullObject | RemarkFullAssignmentType) => {
  let data = null

  if (!info) return data

  data = {
    id: info?.id,
    value: info?.title,
  }

  return data
}

export const getInitialDescription = (description?: RemarkFullDescription) => {
  let data = null

  if (!description?.defect && !description?.custom) return data

  data = {
    id: description?.defect?.id || 0,
    value: description.custom || description.defect?.title || '',
    type: description?.custom ? 'custom' : '',
  }

  return data
}

export const getDataForRequest = (values: RemarkFormData) => {
  const {
    status,
    activityType,
    object,
    senderCompany,
    senderUser,
    comments,
    contractor,
    measures,
    basis,
    description,
    dateStart,
    dateEnd,
    dateDone,
    responsible,
    mediaCommentById,
  } = values

  const dataForRequest: RemarkShort = {
    status,
    assignmentType: Number(activityType?.id),
    comment: comments,
    objectId: Number(object?.id),
    senderCompany: {
      company: senderCompany?.type === 'company' ? senderCompany.value : null,
      projectMember: senderCompany?.type === 'projectMember' ? Number(senderCompany.id) : null,
    },
    senderUser: {
      engineerQC: senderUser?.type === 'engineerQC' ? Number(senderUser.id) : null,
      representative: senderUser?.type === 'representative' ? Number(senderUser.id) : null,
    },
    contractor: {
      company: contractor?.type === 'company' ? contractor?.value : null,
      contractor: contractor?.type === 'projectMember' ? Number(contractor?.id) : null,
    },
    measures: {
      nd: null,
      rd: null,
      custom: measures,
    },
    basis,
    description: {
      defect: description?.type !== 'custom' ? Number(description?.id) : null,
      custom: description?.type === 'custom' ? description?.value : null,
    },
    period: {
      dateStart: formatDateForServer(dateStart),
      dateEnd: formatDateForServer(dateEnd),
      dateDone: dateDone ? formatDateForServer(dateDone) : null,
    },
    responsible: responsible?.value
      ? {
          representative: responsible?.type === 'representative' ? Number(responsible?.id) : null,
          user: responsible?.type === 'user' ? Number(responsible?.id) : null,
        }
      : null,
    mediaCommentById,
  }

  return dataForRequest
}
