import { FieldItem } from '../../pages/Remarks/components/RemarkForm/components/FieldItem'
import { ClearText, SearchTypeControlWrapper, DateRangeWrapper } from './FilterDrawer.styles'
import { FilterDrawerProps, FilterType } from './FilterDrawer.types'
import { FilterList } from './components/FilterList'
import { formateDate } from './components/FilterList/FilterList.utils'
import { Search } from './components/Search'
import { filterDrawerValidation } from './validation'
import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { RemarksFilters } from 'api/remarks/types'
import { Button } from 'components/Button'
import { Divider } from 'components/Divider'
import { FieldForm } from 'components/FieldForm'
import { FormikProvider } from 'formik'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { useForm } from 'hooks/useForm'
import { isEqual } from 'lodash'
import {
  DrawerTopBar,
  DrawerWrapper,
} from 'pages/Administration/References/ReferenceContent/ReferenceDrawers/EditAndAddDrawer/EditAndAddDrawer.styles'
import { PrescriptionFilters } from 'pages/Prescriptions/components/PrescriptionsTable'
import { FormButtonWrapper } from 'pages/Regulations/RegulationsDrawers/DocumentManagment/DocumentManagmentForm/DocumentManagmentForm.styles'
import { useState, useEffect, useMemo, useCallback, ChangeEvent } from 'react'
import { theme } from 'styles/theme'

export const FilterDrawer = <T extends PrescriptionFilters | RemarksFilters>({
  open,
  label,
  columnId,
  filterData,
  setFilterData,
  query,
  api,
  tagName,
  onClose,
  hasDateSelectionRange,

  isRangingDate,
}: FilterDrawerProps<T>) => {
  const [searchValue, setSearchValue] = useState('')
  const [filterType, setFilterType] = useState<FilterType>('list')

  const [localFilterData, setLocalFilterData] = useState<T>(filterData)
  const [rangeLocalFilterData, setLocaleRangeFilterData] = useState<T>()

  const initialValues = useMemo(
    () => ({
      columnId: columnId,
      // @ts-ignore
      dateStart: filterData[`${columnId}_range`]?.after || null,
      // @ts-ignore
      dateEnd: filterData[`${columnId}_range`]?.before || null,
    }),
    [isRangingDate, filterData, columnId],
  )

  const { formik } = useForm({
    validationSchema: filterDrawerValidation,
    enableReinitialize: true,
    initialValues,
    onSubmit: () => {},
  })

  const { values, setFieldValue, dirty: isRangeDirty, isValid: isRangeValid } = formik || {}

  const isRangeApplied = !!formik.values.dateStart || !!formik.values.dateEnd

  useEffect(() => {
    setLocalFilterData(filterData)
    setSearchValue('')

    // @ts-ignore
    if (isRangingDate && filterData[`${columnId}_range`]) setFilterType('range')
    else setFilterType('list')
  }, [open])

  const isDirty = useMemo((): boolean => {
    // @ts-ignore
    return !isEqual([...(localFilterData[columnId] || [])].sort(), [...(filterData[columnId] || [])].sort())
  }, [localFilterData, filterData, open, values])

  const isClearButtonVisible = useMemo(() => {
    // @ts-ignore
    return !!localFilterData[columnId]?.length || values.dateEnd || values.dateStart
  }, [localFilterData, columnId, values])

  const handleConfirm = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        onClose()
        setLocalFilterData(filterData)
        if (isRangingDate) {
          setFieldValue('dateStart', null)
          setFieldValue('dateEnd', null)
        }
      }
    },
    [isRangingDate],
  )

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
  }
  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onSubmitFilter = () => {
    const test = { ...localFilterData }
    if (isRangingDate && filterType === 'range') {
      if (values.dateStart || values.dateEnd) {
        // @ts-ignore
        test[`${columnId}_range`] = {
          after: formateDate(values.dateStart, columnId),
          before: formateDate(values.dateEnd, columnId),
        }
      } else {
        // @ts-ignore
        test[`${columnId}_range`] = null
      }
    }

    setFilterData(test)
    onClose()
  }

  const onFilterClear = () => {
    setLocalFilterData({ ...localFilterData, [columnId]: null })

    if (isRangingDate) {
      setFieldValue('dateStart', null)
      setFieldValue('dateEnd', null)
    }
  }

  const onDrawerClose = () => {
    isDirty || isRangeDirty ? openConfirm() : onClose()
  }

  const onChangeFilterType = (type: FilterType) => {
    setSearchValue('')

    if (type === 'range') {
      setLocalFilterData({ ...localFilterData, [columnId]: null })
    } else {
      setLocalFilterData({ ...localFilterData, [`${columnId}_range`]: null })

      setFieldValue('dateStart', null)
      setFieldValue('dateEnd', null)
    }

    setFilterType(type)
  }

  const getDateStartRange = () => {
    if (columnId === 'period_dateEnd') return undefined
    const { dateEnd } = values || {}
    const today = new Date()

    const range = {
      maxDate: today,
    }

    if (dateEnd && dateEnd < today) range.maxDate = dateEnd

    return range
  }

  return (
    <Drawer anchor='left' open={open} onClose={() => onDrawerClose()}>
      <DrawerWrapper>
        <Stack overflow='auto'>
          <DrawerTopBar>
            <Typography variant='h1' color={theme.palette.primary.main}>
              Фильтрация
            </Typography>
          </DrawerTopBar>

          <Divider />

          <DrawerTopBar>
            <Typography variant='subtitle2' color={theme.palette.text.dark}>
              {label}
            </Typography>
          </DrawerTopBar>

          <Divider />

          {isRangingDate && (
            <SearchTypeControlWrapper>
              <FormControl>
                <RadioGroup>
                  <FormControlLabel
                    control={<Radio />}
                    checked={filterType === 'list'}
                    label='Поиск даты'
                    onChange={() => onChangeFilterType('list')}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    checked={filterType === 'range'}
                    label='Выбор диапазона'
                    onChange={() => onChangeFilterType('range')}
                  />
                </RadioGroup>
              </FormControl>
            </SearchTypeControlWrapper>
          )}

          {filterType === 'list' && <Search key={String(open)} searchValue={searchValue} setValue={setSearchValue} />}

          <Stack padding='12px 20px' alignItems='flex-end'>
            <ClearText isDisabled={!isClearButtonVisible} onClick={onFilterClear} variant='subtitle2'>
              Сбросить
            </ClearText>
          </Stack>

          {filterType === 'range' && (
            <FormikProvider value={formik}>
              <DateRangeWrapper>
                <FieldItem title='c'>
                  <FieldForm
                    version='date'
                    name='dateStart'
                    placeholder='дд.мм.гггг'
                    helperText=''
                    dataValue={formik.values.dateStart}
                    onDataChange={(value: Date | null) => setFieldValue('dateStart', value)}
                    dateFieldProps={{
                      ...getDateStartRange(),
                    }}
                    dateIconType='range'
                  />
                </FieldItem>
                <FieldItem title='по'>
                  <FieldForm
                    version='date'
                    name='dateEnd'
                    placeholder='дд.мм.гггг'
                    helperText=''
                    dataValue={values.dateEnd}
                    onDataChange={(value: Date | null) => setFieldValue('dateEnd', value)}
                    dateFieldProps={{
                      minDate: values.dateStart || undefined,
                      maxDate: columnId === 'period_dateEnd' ? undefined : new Date(),
                    }}
                    dateIconType='range'
                  />
                </FieldItem>
              </DateRangeWrapper>
            </FormikProvider>
          )}

          <FilterList
            searchValue={searchValue}
            query={query}
            columnId={columnId}
            api={api}
            tagName={tagName}
            filterData={filterData}
            localFilterData={localFilterData}
            setLocalFilterData={setLocalFilterData}
            hasDateSelectionRange={hasDateSelectionRange}
            range={{ dateStart: values.dateStart, dateEnd: values.dateEnd }}
            isRangeApplied={isRangeApplied}
            setRangeFilterData={setLocaleRangeFilterData}
            filterType={filterType}
            isRangingDate={isRangingDate || false}
          />
        </Stack>

        <FormButtonWrapper padding={2.5} spacing={1} direction='row'>
          <Stack direction='row' spacing={2} width='100%'>
            <Button
              disabled={filterType === 'list' ? !isDirty : !isRangeDirty}
              onClick={onSubmitFilter}
              color='success'
              size='medium'
              fullWidth
              // loading={isFormLoading}
            >
              Сохранить
            </Button>
            <Button size='medium' fullWidth onClick={() => onDrawerClose()}>
              Закрыть
            </Button>
          </Stack>
        </FormButtonWrapper>
      </DrawerWrapper>

      <ConfirmDialog />
    </Drawer>
  )
}
