import { RemarksTableCell } from '../../RemarksTable.styles'
import { EliminationCellProps } from './EliminationCell.types'
import { Typography } from '@mui/material'
import { EliminationStatus, eliminationStatusRuByEn, eliminationStatusesColor } from 'core/types/elimination'
import { EliminationStatusMarker } from 'pages/Remarks/components/EliminationDrawer/components/EliminationForm/EliminationForm.styles'
import { FC } from 'react'

export const EliminationCell: FC<EliminationCellProps> = ({ info }) => {
  const eliminationStatus = info.getValue<EliminationStatus>()

  const { row, column } = info
  const { minSize, size, maxSize } = column.columnDef
  const { id } = row.original

  return (
    <RemarksTableCell
      key={id}
      style={{
        minWidth: minSize,
        width: size,
        maxWidth: maxSize,
      }}
      className='display-cell audit-cell'
    >
      <EliminationStatusMarker markerColor={eliminationStatusesColor[eliminationStatus]} />
      <Typography variant='body2' width='100%' textAlign='start'>
        {eliminationStatusRuByEn[eliminationStatus]}
      </Typography>
    </RemarksTableCell>
  )
}
