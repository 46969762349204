import { StatusLegendItem } from './PrescriptionStatusLegend.styles'
import { Stack, Typography } from '@mui/material'
import { EliminationStatusMarker as StatusMarker } from 'pages/Remarks/components/EliminationDrawer/components/EliminationForm/EliminationForm.styles'
import { FC } from 'react'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import {
  PRESCRIPTION_CREATED_STATUS_COLOR,
  PRESCRIPTION_DISCARDED_STATUS_COLOR,
  PRESCRIPTION_SENT_STATUS_COLOR,
  PRESCRIPTION_COMPLETE_STATUS_COLOR,
} from 'utils/constants'

export const PrescriptionStatusLegend: FC = () => {
  const { role } = useTypedSelector(profileSelector)

  const statusLegend = [
    { label: 'Создано', color: PRESCRIPTION_CREATED_STATUS_COLOR, hidden: role === 'contractor' },
    { label: 'Снято', color: PRESCRIPTION_DISCARDED_STATUS_COLOR, hidden: false },
    { label: 'Направлено', color: PRESCRIPTION_SENT_STATUS_COLOR, hidden: false },
    { label: 'Исполнено', color: PRESCRIPTION_COMPLETE_STATUS_COLOR, hidden: false },
  ]

  return (
    <Stack direction='row' spacing={1.5} paddingRight='30px'>
      {statusLegend.map(
        (statusData) =>
          !statusData.hidden && (
            <StatusLegendItem>
              <StatusMarker markerColor={statusData.color}></StatusMarker>
              <Typography variant='body1' fontSize={12}>
                {statusData.label}
              </Typography>
            </StatusLegendItem>
          ),
      )}
    </Stack>
  )
}
