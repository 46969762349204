import { RemarkMediaComments, RemarkStatuses } from 'api/remarks/types'
import { CustomSelectValue } from 'components/CustomSelect/CustomSelect.types'
import { EliminationStatus } from 'core/types/elimination'
import { FileWithId, Media } from 'core/types/global'
import { formatDateForServer } from 'utils/dates/formatDateForServer'

export interface EliminationFormProps {
  onFormChange: (dirty: boolean) => void
  onClose: (dirty: boolean, immediately?: boolean) => void
  remarkStatus: RemarkStatuses
  remarkDateStart: Date | null
  primaryId?: number | null
}

export interface EliminationFormData {
  status: EliminationStatus
  contractorReason: string
  engineerReason: string
  measures: string
  responsible: CustomSelectValue | null
  remarkDateStart: Date | null
  dateDone: Date | null

  media: Media[]
  mediaIdsToDelete: number[]
  mediaForCreate: FileWithId[]

  mediaCommentById: null | EliminationMediaComments
  newMediaCommentById: null | EliminationMediaComments
}

export interface EliminationMediaComments extends RemarkMediaComments {}

export const getDataForRequest = (values: EliminationFormData, addedMediaIds?: number[] | null) => {
  const {
    responsible,
    contractorReason,
    engineerReason,

    dateDone,
    measures,

    mediaCommentById,
    newMediaCommentById,
  } = values

  const data = {
    responsible: Number(responsible?.id) || null,
    contractorReason: contractorReason || null,
    engineerReason: engineerReason || null,
    mediaCommentById,
    measures,
    period: {
      dateDone: dateDone ? formatDateForServer(dateDone) : null,
    },
  }

  if (addedMediaIds?.length) {
    if (!data.mediaCommentById) data.mediaCommentById = {}

    Object.values(newMediaCommentById || {}).forEach((newComment: string, index: number) => {
      if (data?.mediaCommentById) data.mediaCommentById[addedMediaIds[index]] = newComment
    })
  }

  return data
}
