import { DashboardStatisticsCard } from './StatisticsCard.styles'
import { StatisticsCardProps } from './StatisticsCard.types'
import { StatisticsCardItem, StatisticsCardItemProps } from './StatisticsCardItem'
import { FC } from 'react'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'
import {
  CALL_CANCELLED_STATUS_COLOR,
  CALL_COMPLETE_STATUS_COLOR,
  CALL_CREATED_STATUS_COLOR,
  CALL_SENT_STATUS_COLOR,
  PRESCRIPTION_COMPLETE_STATUS_COLOR,
  PRESCRIPTION_CREATED_STATUS_COLOR,
  PRESCRIPTION_DISCARDED_STATUS_COLOR,
  PRESCRIPTION_SENT_STATUS_COLOR,
  REMARK_COMPLETE_STATUS_COLOR,
  REMARK_CREATED_STATUS_COLOR,
  REMARK_DISCARDED_STATUS_COLOR,
  REMARK_SENT_STATUS_COLOR,
} from 'utils/constants'

export const StatisticsCard: FC<StatisticsCardProps> = ({ dashboardProject }) => {
  const { role } = useTypedSelector(profileSelector)

  const itemsData: StatisticsCardItemProps[] = [
    {
      title: 'Вызовы',
      id: 'call',
      link: 'calls',
      columns: [
        { title: 'Всего', width: 80 },
        { title: 'Просрочено', width: 80, parentFieldName: 'expired', color: theme.palette.error.main },
      ],
      rows: [
        { title: 'Создан', color: CALL_CREATED_STATUS_COLOR, fieldName: 'created' },
        { title: 'Направлен', color: CALL_SENT_STATUS_COLOR, fieldName: 'sent' },
        { title: 'Всего в работе', fieldName: 'totalInProgress', isSum: true },
        { title: 'Отменен', color: CALL_CANCELLED_STATUS_COLOR, fieldName: 'cancelled' },
        { title: 'Завершен', color: CALL_COMPLETE_STATUS_COLOR, fieldName: 'complete' },
      ],
      style: { flex: 1, minWidth: '296px' },
    },
    {
      title: 'Замечания',
      id: 'shortcoming',
      link: 'remarks',
      columns: [
        { title: 'Всего', width: 85 },
        { title: 'Первичные', width: 85, parentFieldName: 'primary' },
        { title: 'Повторные', width: 85, parentFieldName: 'secondary' },
        { title: 'Просрочено', width: 85, parentFieldName: 'expired', color: theme.palette.error.main },
      ],
      rows: [
        { title: 'Создано', color: REMARK_CREATED_STATUS_COLOR, fieldName: 'created', hidden: role === 'contractor' },
        { title: 'Направлено', color: REMARK_SENT_STATUS_COLOR, fieldName: 'sent' },
        { title: 'Всего в работе', fieldName: 'totalInWork', isSum: true },
        { title: 'Снято', color: REMARK_DISCARDED_STATUS_COLOR, fieldName: 'discarded' },
        { title: 'Исполнено', color: REMARK_COMPLETE_STATUS_COLOR, fieldName: 'complete' },
      ],
      style: { flex: 2 },
    },
    {
      title: 'Предписания',
      id: 'prescription',
      link: 'prescriptions',
      tabs: [
        { id: 'ALL', text: 'ВСЕ' },
        { id: 'INCOMING', text: 'ВХ.' },
        { id: 'OUTGOING', text: 'ИСХ.' },
      ],
      columns: [
        { title: 'Всего', width: 100 },
        { title: 'Просрочено', width: 100, parentFieldName: 'expired', color: theme.palette.error.main },
      ],
      rows: [
        {
          title: 'Создано',
          color: PRESCRIPTION_CREATED_STATUS_COLOR,
          fieldName: 'created',
          hidden: role === 'contractor',
        },
        { title: 'Направлено', color: PRESCRIPTION_SENT_STATUS_COLOR, fieldName: 'sent' },
        { title: 'Всего в работе', fieldName: 'totalInWork', isSum: true },
        { title: 'Снято', color: PRESCRIPTION_DISCARDED_STATUS_COLOR, fieldName: 'discarded' },
        { title: 'Исполнено', color: PRESCRIPTION_COMPLETE_STATUS_COLOR, fieldName: 'complete' },
      ],
      style: { flex: 1 },
    },
  ]

  return (
    <DashboardStatisticsCard>
      {itemsData.map((item) => {
        let data = null
        if (dashboardProject && item.id) data = dashboardProject.statistics[item.id]

        return <StatisticsCardItem {...{ ...item, data }} />
      })}
    </DashboardStatisticsCard>
  )
}
