import { PrescriptionFormData } from '../../PrescriptionsForm.types'
import { Wrapper } from './Info.styles'
import { directionDropdown, InfoProps, inspectionTypeDropdown, typeDropdown } from './Info.types'
import {
  EventAvailable as EventAvailableIcon,
  StickyNote2Outlined as StickyNoteIcon,
  ReceiptLongOutlined as TypeIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  ForwardToInboxOutlined as DirectionIcon,
} from '@mui/icons-material'
import { IconButton, Menu, Stack, Typography } from '@mui/material'
import { ColoredTitle } from 'components/ColoredTitle'
import { CustomSelect } from 'components/CustomSelect'
import { FieldForm } from 'components/FieldForm'
import { StyledSelectMenuItem } from 'components/UserManagement/components/UserRoleSelection'
import {
  PrescriptionStatus,
  prescriptionStatusEnByRu,
  prescriptionStatusRuByEn,
  prescriptionStatuses,
  prescriptionStatusesColor,
} from 'core/types/prescription'
import { useFormikContext } from 'formik'
import { FieldItem } from 'pages/Remarks/components/RemarkForm/components/FieldItem'
import { StatusCellContent } from 'pages/Remarks/components/RemarksTable/cells/StatusCell'
import { FC, useState, useCallback, MouseEvent } from 'react'
import { useParams } from 'react-router-dom'
import { useTypedSelector } from 'store'
import { profileSelector } from 'store/slices/profile'
import { theme } from 'styles/theme'
import { Documents } from '../Documents'
import { ItemWrapper } from 'pages/Remarks/components/RemarkForm/RemarkForm.styles'
import { NumberIcon } from 'assets/icons/NumberIcon'
import { MAX_FILE_SIZE_IN_MB } from 'utils/constants'
import { prescriptionMediaFileExtensions } from 'core/types/file'

export const Info: FC<InfoProps> = ({ viewingOnly, formMode, isStatusChangeAvailable }) => {
  const profile = useTypedSelector(profileSelector)
  const { initialValues, values, setFieldValue } = useFormikContext<PrescriptionFormData>()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const numberEditingAccess: boolean = profile.role === 'admin'
  const addDocumentButtonDisabled: boolean = initialValues.status !== 'CREATED'

  const onStatusCellClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setMenuAnchor(e.currentTarget)
  }, [])

  const ColoredStatus = (
    <ColoredTitle body={prescriptionStatusRuByEn[values.status]} color={prescriptionStatusesColor[values.status]} />
  )

  const onMenuClose = useCallback(() => {
    setMenuAnchor(null)
  }, [])

  const onMenuClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }, [])

  const onMenuItemClick = useCallback(
    (status: PrescriptionStatus) => {
      setMenuAnchor(null)
      setFieldValue('status', status)
    },
    [projectId],
  )

  return (
    <Wrapper spacing={2.5}>
      <ItemWrapper>
        <Typography marginBottom={1.5} variant='subtitle1' textAlign='start' color={theme.palette.text.dark}>
          Общая информация
        </Typography>
        <Stack spacing={1.5} marginBottom={5.5}>
          {/* @ts-ignore */}
          <FieldItem title='Статус' marginBottom={1}>
            <StatusCellContent onClick={onStatusCellClick} display='flex' alignItems='center'>
              {ColoredStatus}
              {isStatusChangeAvailable && (
                <IconButton>
                  <KeyboardArrowDownIcon fontSize='small' />
                </IconButton>
              )}
            </StatusCellContent>
            {isStatusChangeAvailable && (
              <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClick={onMenuClick} onClose={onMenuClose}>
                {prescriptionStatuses
                  .filter((menuStatus) => menuStatus !== prescriptionStatusRuByEn[values.status])
                  .map((menuStatus) => {
                    const valueForOnClick = prescriptionStatusEnByRu[menuStatus]
                    const checked = values.status === valueForOnClick

                    return (
                      <StyledSelectMenuItem
                        onClick={() => onMenuItemClick(valueForOnClick)}
                        style={{ width: '100%', maxWidth: '100%' }}
                        value={menuStatus}
                        checked={checked}
                        key={menuStatus}
                      >
                        {menuStatus}
                      </StyledSelectMenuItem>
                    )
                  })}
              </Menu>
            )}
          </FieldItem>
          <FieldItem title='Номер' icon={<NumberIcon fontSize='medium' color='secondary' />} isRequired={true}>
            <FieldForm
              version='project'
              placeholder='123'
              name='number'
              dataValue={values.number}
              helperText=''
              fullWidth={false}
              disabled={!numberEditingAccess}
              inputProps={{
                readOnly: !numberEditingAccess,
                style: { padding: '5px 8px', width: '184px', textAlign: 'end' },
              }}
            />
          </FieldItem>
          <FieldItem
            title='Дата предписания'
            icon={<EventAvailableIcon fontSize='medium' color='secondary' />}
            isRequired={true}
          >
            <FieldForm
              version='date'
              name='dateStart'
              placeholder='дд.мм.гггг'
              helperText=''
              dataValue={values.dateStart}
              onDataChange={(value: Date | null) => setFieldValue('dateStart', value)}
              style={{ maxWidth: '144px', width: '100%' }}
              dateFieldProps={{
                maxDate: new Date() || undefined,
                readOnly: viewingOnly,
              }}
            />
          </FieldItem>
          <FieldItem
            title='Вид предписания'
            icon={<DirectionIcon fontSize='medium' color='secondary' />}
            isRequired={true}
          >
            <CustomSelect
              name='direction'
              list={directionDropdown || []}
              placeholder='Выберите вид'
              isSearch={true}
              width={200}
              readOnly={viewingOnly}
            />
          </FieldItem>
          <FieldItem
            title='Вид обследования'
            icon={<StickyNoteIcon fontSize='medium' color='secondary' />}
            isRequired={true}
          >
            <CustomSelect
              name='inspectionType'
              list={inspectionTypeDropdown || []}
              placeholder={!viewingOnly ? 'Выберите вид' : ''}
              isSearch={true}
              width={200}
              readOnly={viewingOnly}
            />
          </FieldItem>
          <FieldItem title='Тип предписания' icon={<TypeIcon fontSize='medium' color='secondary' />} isRequired={true}>
            <CustomSelect
              name='type'
              list={typeDropdown || []}
              placeholder='Выберите тип'
              isSearch={true}
              width={200}
              readOnly={viewingOnly}
            />
          </FieldItem>
        </Stack>
        <Stack>
          <Typography variant='subtitle1' textAlign='start' marginBottom='16px' color={theme.palette.text.dark}>
            Комментарии
          </Typography>
          <FieldForm
            version='project'
            name='comment'
            placeholder={!viewingOnly ? 'Комментарий' : ''}
            multiline
            inputProps={{
              readOnly: viewingOnly,
              style: {
                minHeight: '60px',
              },
            }}
          />
        </Stack>
      </ItemWrapper>

      <ItemWrapper flex={1}>
        <Documents
          viewingOnly={viewingOnly}
          addDocumentButtonDisabled={addDocumentButtonDisabled}
          acceptedExtensions={prescriptionMediaFileExtensions}
          tooltipTitle={`В предписание можно добавить файлы в форматах: PDF, doc(x). Размер файла не должен превышать ${MAX_FILE_SIZE_IN_MB} МБ.`}
        />
      </ItemWrapper>
    </Wrapper>
  )
}
