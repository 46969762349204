import { Stack, Typography } from '@mui/material'
import { IasLogoIcon } from 'assets/icons/IasLogoIcon'
import { Form, FormikProvider } from 'formik'
import { useForm } from 'hooks/useForm'
import { FC, useEffect } from 'react'
import { SettingsFormHeader, SettingsFormItem, SettingsFormRow } from './SettingsForm.styles'
import { theme } from 'styles/theme'
import { CloudCogIcon } from 'assets/icons/CloudCogIcon'
import { FieldForm } from 'components/FieldForm'
import { FieldFormLabel } from 'components/FieldFormLabel'
import { Button } from 'components/Button'
import { useSnackbar } from 'notistack'
import { DirtyFormForGlobalConfirm } from 'core/types/global'

export const SettingsForm: FC = () => {
  const { enqueueSnackbar } = useSnackbar()

  const { formik } = useForm({
    enableReinitialize: true,
    initialValues: {
      login: localStorage.getItem('integrationLogin') || '',
      password: localStorage.getItem('integrationPass') || '',
    },
    onSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {
        localStorage.setItem('integrationLogin', values.login)
        localStorage.setItem('integrationPass', values.password)

        enqueueSnackbar('Авторизация в данный момент недоступна.', { variant: 'error' })
        setSubmitting(false)
      }, 1000)
    },
  })

  const { values, dirty: dirtyByStorage, setValues, resetForm, isSubmitting } = formik

  useEffect(() => {
    const dirtyFormType: DirtyFormForGlobalConfirm = 'settings'
    if (dirtyByStorage) localStorage.setItem('dirtyForm', dirtyFormType)
    else localStorage.removeItem('dirtyForm')
  }, [dirtyByStorage])

  const dirty: boolean = Boolean(values.login || values.password)
  const valid: boolean = Boolean(values.login && values.password)

  const onClearFormClick = () => {
    setValues({ login: '', password: '' })
    localStorage.removeItem('integrationLogin')
    localStorage.removeItem('integrationPass')
  }

  return (
    <FormikProvider value={formik}>
      <Stack width='50%' component={Form}>
        <SettingsFormHeader>
          <Stack padding={2.5} direction='row' spacing={1} alignItems='center'>
            <IasLogoIcon />
            <Typography variant='body2' fontSize={20}>
              ИАС «Мониторинг»
            </Typography>
          </Stack>
          <Button onClick={onClearFormClick} disabled={!dirty} variant='outlined' color='secondary'>
            <Typography variant='body2' color={theme.palette.primary[dirty ? 'main' : 'light']}>
              Очистить данные
            </Typography>
          </Button>
        </SettingsFormHeader>

        <Stack spacing={5} padding='20px 15px 20px 30px'>
          <SettingsFormRow>
            <SettingsFormItem height='100%'>
              <Typography textAlign='start' color={theme.palette.text.dark} variant='subtitle2'>
                Статус подключения
              </Typography>
            </SettingsFormItem>

            <Stack width='50%'>
              <Stack flexWrap='wrap' direction='row' alignItems='center' justifyContent='space-between'>
                <Stack direction='row' alignItems='center' gap={1}>
                  <CloudCogIcon color='info' />
                  <Typography textAlign='start' color={theme.palette.primary.main} variant='body2' lineHeight='normal'>
                    Настройка подключения
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </SettingsFormRow>

          <SettingsFormRow _alignItems='flex-start'>
            <SettingsFormItem _direction='column' spacing={1} _alignItems='flex-start'>
              <Typography textAlign='start' color={theme.palette.text.dark} variant='subtitle2'>
                Данные аккаунта
              </Typography>
              <Typography textAlign='start' variant='body2'>
                Для удаленной авторизации укажите логин и пароль от учётной записи вашей компании в ИАС «Мониторинг»
              </Typography>
            </SettingsFormItem>

            <SettingsFormItem _direction='column' spacing={1.5}>
              <FieldFormLabel label='Логин' isRequired>
                <FieldForm name='login' version='project' placeholder='Введите логин' helperText='' />
              </FieldFormLabel>

              <FieldFormLabel label='Пароль' isRequired>
                <FieldForm
                  name='password'
                  type='password'
                  version='project'
                  placeholder='Введите пароль'
                  helperText=''
                />
              </FieldFormLabel>
            </SettingsFormItem>
          </SettingsFormRow>

          <SettingsFormRow>
            <SettingsFormItem></SettingsFormItem>
            <SettingsFormItem>
              <Button type='submit' loading={isSubmitting} color='success' disabled={!dirty || !valid}>
                Подключить
              </Button>
            </SettingsFormItem>
          </SettingsFormRow>
        </Stack>
      </Stack>
    </FormikProvider>
  )
}
