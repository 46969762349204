import { AuditDrawer } from '../../../Remarks/components/AuditDrawer'
import {
  CustomColumnDef,
  RemarksTableBody,
  RemarksTableCell,
  RemarksTableContainer,
  RemarksTableHead,
  RemarksTableRow,
} from '../../../Remarks/components/RemarksTable'
import { AuditCell } from '../../../Remarks/components/RemarksTable/cells/AuditCell'
import {
  getStatusColumn,
  PrescriptionsTableProps,
  PrescriptionNotificationData,
  PrescriptionTableDialogTrigger,
  PrescriptionFilters,
  countColumn,
} from './PrescriptionsTable.types'
import { KeyboardArrowDown as ArrowIcon, FilterAlt as FilterIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import {
  prescriptionsApi,
  useGetColumnSettingsQuery,
  useGetPrescriptionFiltredlistQuery,
  useGetPrescriptionsQuery,
  useLazyCheckPrescriptionStatusForEmailNotificationQuery,
  useLazyEditPrescriptionStatusQuery,
} from 'api/prescriptions'
import { Prescription, prescriptionColumnByValue } from 'api/prescriptions/types'
import { EmptyPageData, EmptyPage } from 'components/EmptyPage'
import { FilterDrawer } from 'components/FilterDrawer'
import { Progress } from 'components/Progress'
import { TABLE_CELL_HEIGHT } from 'components/Table/components/TableCell/TableCell.styles'
import {
  PrescriptionFilterableColumn,
  PrescriptionStatus,
  SortableColumn,
  SortingOrder,
  filterableColumns,
  rangingDates,
  sortableColumns,
} from 'core/types/prescription'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import {
  IconButtonWrapper,
  TableCell,
  TableCellWrapper,
} from 'pages/Documents/components/DocumentsTable/DocumentsTable.styles'
import { TableWrapper } from 'pages/Ksg/components/WorkTable/WorkTable.styles'
import { getEmptyPageData } from 'pages/Projects'
import { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { prescriptionsShouldResetPageSelector } from 'store/slices/infiniteScroll'
import { SortButton } from 'styles/commonTableStyles'
import { theme } from 'styles/theme'
import { NUMBER_OF_TABLE_ITEMS_TO_FETCH } from 'utils/constants'
import { formatDateForServer } from 'utils/dates/formatDateForServer'

export const PrescriptionsTable: FC<PrescriptionsTableProps> = ({ sort, filter }) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { sortableColumn, setSortableColumn } = sort || {}
  const { filterData, setFilterData } = filter || {}

  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const [notificationData, setNotificationData] = useState<PrescriptionNotificationData | null>(null)

  // Location
  const location = useLocation()
  const locationState = location.state || {}
  const { prescriptionId: locationPrescriptionId, prescriptionsPage } = locationState || ({} as any)

  const onRowClick = (id: number) => (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    navigate(`edit/${id}`, { state: { sortableColumn, filterData, currentPage } })
  }

  // Audit
  const [openedAuditId, setOpenedAuditId] = useState<number | null>(null)

  const onAuditOpen = (id: number) => {
    setOpenedAuditId(id)
  }

  const onAuditClose = () => {
    setOpenedAuditId(null)
  }

  // Filter
  const [filterDrawerData, setFilterDrawerData] = useState({
    label: '',
    columnId: '',
    open: false,
  })

  const onFilterDrawerOepn = (columnId: PrescriptionFilterableColumn, columnName: string) => {
    setFilterDrawerData({
      label: columnName,
      columnId: columnId,
      open: true,
    })
  }

  const onFilterDrawerClose = () => {
    setFilterDrawerData({ ...filterDrawerData, open: false })
  }

  // Status cell
  const [editPrescriptionStatus] = useLazyEditPrescriptionStatusQuery()
  const [checkPrescriptionStatusForEmailNotification, { ...checkPrescriptionStatusForEmailNotificationResult }] =
    useLazyCheckPrescriptionStatusForEmailNotificationQuery()

  const onStatusSelect = (
    projectId: number,
    prescriptionId: number,
    status: PrescriptionStatus,
    dateDone: string,
    isShouldNotify = false,
  ) => {
    const today = new Date()
    const isShouldSetDateDone = !dateDone && status === 'COMPLETE'

    if (status !== 'COMPLETE' && dateDone)
      enqueueSnackbar(
        'Предписание имеет факт. дату устранения. Измените статус на Исполнено, либо удалите факт. дату устранения.',
        { variant: 'error' },
      )
    else {
      if (status === 'COMPLETE' || status === 'DISCARDED' || status === 'SENT') {
        if (notificationData) setNotificationData(null)
        else {
          setNotificationData({
            status,
            prescriptionId,
            dateDone,
          })
          checkPrescriptionStatusForEmailNotification({
            projectId,
            prescriptionId,
            status: status,
            completion: isShouldSetDateDone ? formatDateForServer(today) : undefined,
          })

          return
        }
      }

      editPrescriptionStatus({
        projectId,
        prescriptionId,
        status,
        dateDone: isShouldSetDateDone ? formatDateForServer(today) : undefined,
        notification: isShouldNotify,

        sort: sortableColumn,
        filter: filterData,
      })
    }
  }

  useMutationHandlers(checkPrescriptionStatusForEmailNotificationResult, (data) => {
    if (data.data) {
      setConfirmDialogTrigger('notification')
      openConfirm()
    } else {
      const { status, prescriptionId, dateDone } = notificationData as PrescriptionNotificationData
      onStatusSelect(projectId, prescriptionId, status, dateDone || '', false)
    }
  })

  // Columns
  const { data: columnsData } = useGetColumnSettingsQuery({ projectId })
  const columnHelper = createColumnHelper<Prescription>()

  const auditColumn: CustomColumnDef<Prescription> = {
    ...columnHelper.display({
      id: 'actions',
      minSize: 50,
      size: 50,
      maxSize: 50,
      cell: (info) => <AuditCell info={info} onAuditOpen={onAuditOpen} />,
    }),
    isCustomCell: true,
  }

  const [columns, setColumns] = useState<CustomColumnDef<Prescription>[]>([])

  useEffect(() => {
    const { columns: selectedColumns = [] } = columnsData || {}
    const tempColumns = []

    selectedColumns?.forEach((selectedColumn) => {
      if (selectedColumn === 'STATUS') {
        tempColumns.push(getStatusColumn(onStatusSelect))

        return
      }

      tempColumns.push(prescriptionColumnByValue[selectedColumn])
    })

    tempColumns.push(countColumn)
    tempColumns.push(auditColumn)
    setColumns(tempColumns)
  }, [columnsData, sort, filter])

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const { queryData, onScrollWithInfiniteLoad, currentPage } = useInfiniteScroll({
    refElement: tableContainerRef,
    api: prescriptionsApi,
    tagName: 'Prescriptions',
    limit: NUMBER_OF_TABLE_ITEMS_TO_FETCH,
    query: useGetPrescriptionsQuery,
    startPage: prescriptionsPage || 1,
    shouldResetPageSelector: prescriptionsShouldResetPageSelector,
    arg: {
      projectId,
      sort: sortableColumn,
      filter: filterData,
    },
    table: 'prescriptions',
  })

  const { data = [] } = queryData.data || {}
  const { isLoading } = queryData || {}

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  })

  const { rows } = table.getRowModel()

  const virtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => TABLE_CELL_HEIGHT,
    overscan: 5,
    getScrollElement: () => tableContainerRef.current,
  })

  const emptyPageData: EmptyPageData = getEmptyPageData(
    <Stack>
      <div>Предписания отсутствуют.</div>
      <div>Создание предписаний возможно только после заполнения справочников проекта.</div>
      <div>
        Перед созданием предписаний добавьте, пожалуйста, данные в справочники проекта: Участники проекта, Представители
        участников проекта.
      </div>
    </Stack>,
  )

  const onSortColumn = (columnId: SortableColumn) => {
    let order: SortingOrder = null
    if (sortableColumn?.column === columnId || !sortableColumn.column) {
      if (!sortableColumn?.order) order = 'ASC'
      else if (sortableColumn.order === 'ASC') order = 'DESC'
    } else order = 'ASC'

    if (sortableColumn?.order === 'DESC' && sortableColumn?.column === columnId)
      setSortableColumn({ column: null, order: null })
    else setSortableColumn({ column: columnId, order: order })
  }

  const handleNotificationConfirm = useCallback(
    (confirm: boolean) => {
      const { status, prescriptionId, dateDone } = notificationData as PrescriptionNotificationData

      if (confirm) onStatusSelect(projectId, prescriptionId, status, dateDone || '', true)
      else onStatusSelect(projectId, prescriptionId, status, dateDone || '', false)
    },
    [notificationData],
  )

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<PrescriptionTableDialogTrigger>('notification')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    notification: {
      handleConfirm: handleNotificationConfirm,
      title: 'Отправить уведомление?',
      body: (
        <Typography variant='body2' color={theme.palette.text.dark}>
          Уведомление о смене статуса будет направлено на e-mail представителей подрядчика.
        </Typography>
      ),
      denyButtonText: 'нет',
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  // Positioning
  const [highlightedId, setHighlightedId] = useState<number | null>(null)
  // For experimental pagination in future
  // const [showObserverBounds, setShowObserverBounds] = useState(locationPrescriptionId && data.length ? false : true)
  // useEffect(() => {
  //   if (highlightedId) setShowObserverBounds(true)
  // }, [highlightedId])

  useEffect(() => {
    if (locationPrescriptionId) {
      const rowIndex = data.findIndex((item) => item.id === locationPrescriptionId)
      if (rowIndex >= 0) {
        setTimeout(() => scrollToItem(rowIndex), 500)
        // scrollToItem(rowIndex)

        setHighlightedId(locationPrescriptionId)
      }
    }
  }, [locationPrescriptionId])

  const onHoverRow = (rowId: number) => {
    if (highlightedId === rowId) setHighlightedId(null)
  }

  const scrollToItem = (index: number) => {
    if (index >= 0) virtualizer.scrollToIndex(index + 1, { align: 'center', behavior: 'auto' })
  }

  return (
    <>
      {isLoading && <Progress />}

      {!isLoading && !!data.length && (
        <TableWrapper>
          <RemarksTableContainer onScroll={onScrollWithInfiniteLoad} ref={tableContainerRef}>
            <RemarksTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <RemarksTableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const isSortableColumn = sortableColumns.includes(header.id as SortableColumn)
                    const isFilterableColumn = filterableColumns.includes(header.id as PrescriptionFilterableColumn)
                    let order = null

                    if (isSortableColumn && sortableColumn?.column === header.id) {
                      order = sortableColumn?.order
                    }

                    // @ts-ignore
                    let isFilterApplied = !!filterData[header.id as PrescriptionFilterableColumn]?.length

                    // @ts-ignore
                    if (
                      rangingDates.includes(header.id as PrescriptionFilterableColumn) &&
                      filterData?.[`${header.id}_range` as keyof PrescriptionFilters]
                    )
                      isFilterApplied = true

                    return (
                      <RemarksTableCell
                        key={header.id}
                        style={{
                          flex: 1,
                          minWidth: header.column.columnDef.minSize,
                          width: header.column.columnDef.size,
                          maxWidth: header.column.columnDef.maxSize,
                          justifyContent: 'space-between',
                        }}
                      >
                        <Stack width={24}>
                          {isSortableColumn && (
                            <SortButton onClick={() => onSortColumn(header.id as SortableColumn)} sorting={order}>
                              <ArrowIcon />
                              <ArrowIcon />
                            </SortButton>
                          )}
                        </Stack>
                        <Typography variant='body2'>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </Typography>
                        <Stack width={24}>
                          {isFilterableColumn && (
                            <IconButtonWrapper
                              onClick={() =>
                                onFilterDrawerOepn(
                                  header.id as PrescriptionFilterableColumn,
                                  header.column.columnDef.header as string,
                                )
                              }
                            >
                              {isFilterApplied ? (
                                <FilterIcon style={{ fill: theme.palette.text.light }} fontSize='inherit' />
                              ) : (
                                <FilterIcon
                                  style={{ fill: theme.palette.text.light, opacity: 0.5 }}
                                  fontSize='inherit'
                                />
                              )}
                            </IconButtonWrapper>
                          )}
                        </Stack>
                      </RemarksTableCell>
                    )
                  })}
                </RemarksTableRow>
              ))}
            </RemarksTableHead>

            <RemarksTableBody style={{ height: virtualizer.getTotalSize() }}>
              {virtualizer.getVirtualItems().map((row) => {
                const prescriptionId = data[row.index].id

                return (
                  <RemarksTableRow
                    onClick={onRowClick(prescriptionId)}
                    showAnimation={prescriptionId === highlightedId}
                    onMouseEnter={() => onHoverRow(prescriptionId)}
                    key={row.key}
                    data-index={row.index}
                    ref={virtualizer.measureElement}
                    style={{
                      width: '100%',
                      transform: `translateY(${row.start - virtualizer.options.scrollMargin}px)`,
                    }}
                  >
                    {columns.map((column, columnIndex) => {
                      const cell = rows[row.index].getVisibleCells()[columnIndex]
                      const { minSize, size, maxSize, textAlign } = column

                      if (column.isCustomCell) {
                        return flexRender(cell.column.columnDef.cell, cell.getContext())
                      }

                      return (
                        <TableCellWrapper
                          key={column.id}
                          style={{
                            flex: 1,
                            minWidth: minSize,
                            width: size,
                            maxWidth: maxSize,
                          }}
                        >
                          <TableCell className='cell'>
                            <Typography variant='body2' width='100%' textAlign={textAlign}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </Typography>
                          </TableCell>
                        </TableCellWrapper>
                      )
                    })}
                  </RemarksTableRow>
                )
              })}
            </RemarksTableBody>

            <AuditDrawer
              variant='prescriptions'
              openedAuditId={openedAuditId}
              onClose={onAuditClose}
              key={projectId + 'prescriptions' + (openedAuditId ?? '')}
            />

            <FilterDrawer
              label={filterDrawerData.label}
              open={filterDrawerData.open}
              columnId={filterDrawerData.columnId as PrescriptionFilterableColumn}
              filterData={filterData}
              setFilterData={setFilterData}
              api={prescriptionsApi}
              tagName='Prescriptions'
              query={useGetPrescriptionFiltredlistQuery}
              onClose={onFilterDrawerClose}
              isRangingDate={rangingDates.includes(filterDrawerData.columnId as PrescriptionFilterableColumn)}
            />
          </RemarksTableContainer>

          <ConfirmDialog />
        </TableWrapper>
      )}

      {!isLoading && !data.length && <EmptyPage data={emptyPageData} fullPage />}
    </>
  )
}
