import { BindRemarksDrawer } from '../BindRemarksDrawer'
import { EliminationResultDrawer } from '../EliminationResultDrawer'
import { FormItemsWrapper, FormWrapper, FullWrapper } from './PrescriptionsForm.styles'
import {
  getDataForRequest,
  getInitialContractor,
  getInitialDirection,
  getInitialInspectionType,
  getInitialReceiverOrExecutionControl,
  getInitialSenderCompany,
  getInitialSenderUser,
  getInitialType,
  PrescriptionAutofilledData,
  PrescriptionDrawerType,
  PrescriptionFormData,
  PrescriptionFormDialogTrigger,
  PrescriptionLocationState,
} from './PrescriptionsForm.types'
import { Control } from './components/Control'
import { Info } from './components/Info'
import { Remarks } from './components/Remarks'
import { validationPrescription } from './validation'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import {
  prescriptionsApi,
  useBindRemarksMutation,
  useCreatePrescriptionMutation,
  useDeleteFilesFromPrescriptionMutation,
  useDeletePrescriptionMutation,
  useEditPrescriptionMutation,
  useGetBindRemarksQuery,
  useGetNextPrescriptionNumberQuery,
  useGetPrescriptionByIdQuery,
  useLazyCheckPrescriptionForEmailNotificationQuery,
  useLazyCheckPrescriptionForFilterSatisfactionQuery,
  useLazyCheckPrescriptionNumberForUniquenessQuery,
  useLazyFormPrescriptionQuery,
  useUploadFilesToPrescriptionMutation,
} from 'api/prescriptions'
import { RemarkFull } from 'api/remarks/types'
import { Button } from 'components/Button'
import { Progress } from 'components/Progress'
import { SimpleHeader } from 'components/SimpleHeader'
import { Form, FormikProvider } from 'formik'
import { useConfirmDialog, UseExitConfirmProps } from 'hooks/useConfirmDialog'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { isEqual } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useTypedSelector } from 'store'
import { isUserHighAccessSelector, profileSelector } from 'store/slices/profile'
import { theme } from 'styles/theme'
import { parseResponseDate } from 'utils/dates/parseResponseDate'
import { downloadMedia } from 'utils/downloadMedia'
import { TTagType } from 'api/api.types'
import { AuditButton } from 'pages/Remarks/components/AuditDrawer/components/AuditButton'
import { AuditDrawer } from 'pages/Remarks/components/AuditDrawer'
import { useSnackbar } from 'hooks/useSnackbar'

export const PrescriptionsForm: FC = () => {
  const profile = useTypedSelector(profileSelector)
  const isUserHighAccess = useTypedSelector(isUserHighAccessSelector)
  const viewingOnly = !isUserHighAccess
  const { projectId: projectIdString, prescriptionId: prescriptionIdString } = useParams()
  const projectId = Number(projectIdString)
  const prescriptionId = Number(prescriptionIdString) || undefined
  const navigate = useNavigate()
  const [isPrescriptionForming, setIsPrescriptionForming] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const [newRemarkId, setNewRemarkId] = useState<number | null>(null)

  // Location
  const location = useLocation()
  const locationState: PrescriptionLocationState = (location?.state as PrescriptionLocationState) || {}

  // Clearing locationState
  useEffect(() => {
    if (locationState) navigate(location.pathname, { replace: true })
  }, [])

  const savedLocationState = useMemo(() => locationState, [])

  const { sortableColumn, filterData, prescriptionValues, currentPage, backUrl, backState } = savedLocationState || {}

  const formMode: 'add' | 'edit' = location.pathname.includes('/add') ? 'add' : 'edit'

  const {
    data: prescriptionData,
    isFetching: isPrescriptionDataFetching,
    isLoading: isPrescriptionLoading,
  } = useGetPrescriptionByIdQuery(
    {
      projectId,
      prescriptionId: prescriptionId!,

      sort: sortableColumn,
      filter: filterData,
    },
    {
      skip: !prescriptionId,
    },
  )

  const {
    status,
    number,
    period,
    inspectionType,
    type,
    comment,
    senderCompany,
    senderUser,
    contractor,
    receiver,
    executionControl,
    attachments,
    preloadedShortcomings,
    canEditStatus,
    author,
    direction,
  } = prescriptionData || {}

  const { dateStart, dateEnd, dateDone, automaticDateEnd } = period || {}

  const [createPrescription, { isLoading: isCreating, ...createPrescriptionResponse }] = useCreatePrescriptionMutation()
  const [editPrescription, { isLoading: isEditing, ...editPrescriptionResponse }] = useEditPrescriptionMutation()
  const [deletePrescription, { isLoading: isDeleting, ...deletePrescriptionResponse }] = useDeletePrescriptionMutation()
  const [uploadFilesToPrescriptionRequest, { isLoading: isFilesUploading, ...uploadFilesToPrescriptionResponse }] =
    useUploadFilesToPrescriptionMutation()
  const [deleteFilesFromPrescriptionRequest, { isLoading: isFilesDeleting, ...deleteFilesFromPrescriptionResponse }] =
    useDeleteFilesFromPrescriptionMutation()
  const [formPrescriptionRequest, formPrescriptionResult] = useLazyFormPrescriptionQuery()
  // const [getBindRemarks, { data: boundRemarksData, isSuccess: isBoundRemarksSuccess }] = useGetBindRemarksMutation()

  const [bindRemarks, { isLoading: isBindRemarksLoading, ...bindRemarksResponse }] = useBindRemarksMutation()
  const [checkForFilterSatisfaction, { isLoading: isSatisfactionChecking, ...checkForFilterSatisfactionResult }] =
    useLazyCheckPrescriptionForFilterSatisfactionQuery()
  const [
    checkPrescriptionForEmailNotification,
    { isLoading: isEmailNotificationChecking, ...checkEmailNotificationResult },
  ] = useLazyCheckPrescriptionForEmailNotificationQuery()
  const [
    checkNumberForUniqueness,
    { isLoading: isCheckNumberForUniquenessLoading, ...checkNumberForUniquenessResponse },
  ] = useLazyCheckPrescriptionNumberForUniquenessQuery() // isLoading or isFetching?

  const isQuerying =
    isCreating ||
    isEditing ||
    isFilesUploading ||
    isFilesDeleting ||
    isBindRemarksLoading ||
    isCheckNumberForUniquenessLoading

  const [isFormLoading, setIsFormLoading] = useState(false)
  const [isShouldSendNotification, setIsShouldSendNotification] = useState(false)
  const { showSnackbar, enqueueSnackbar } = useSnackbar({
    snackbarTrigger: isFormLoading,
    fullSuccessText: newRemarkId ? 'Предписание успешно добавлено.' : 'Предписание успешно изменено.',
  })

  const isStatusChangeAvailable = !viewingOnly && formMode !== 'add' && canEditStatus
  // checkForFilterSatisfaction

  useEffect(() => {
    if (isQuerying) setIsFormLoading(true)
  }, [isQuerying])

  const [autofilledData, setAutofilledData] = useState<PrescriptionAutofilledData>({
    contractor: null,
  })

  const {
    data: nextNumber,
    isFetching: isNextNumberFetching,
    isLoading: isPrescriptionNumberLoading,
  } = useGetNextPrescriptionNumberQuery(projectId)

  const initialValues = useMemo(() => {
    const data: PrescriptionFormData = {
      status: status || 'CREATED',
      number: number || nextNumber || '',
      dateStart: parseResponseDate(dateStart).date || new Date(new Date().setHours(0, 0, 0, 0)),
      direction: getInitialDirection(direction),
      inspectionType: getInitialInspectionType(inspectionType),
      type: getInitialType(type),
      comment: comment || '',
      senderCompany: getInitialSenderCompany(profile, senderCompany, prescriptionId),
      senderUser: getInitialSenderUser(profile, senderUser, prescriptionId),
      contractor: getInitialContractor(contractor),
      receiver: getInitialReceiverOrExecutionControl(receiver),
      executionControl: getInitialReceiverOrExecutionControl(executionControl),
      files: attachments || [],
      filesForCreate: [],
      filesIdsToDelete: [],

      dateEnd: parseResponseDate(dateEnd).date,
      dateDone: parseResponseDate(dateDone).date,

      remarks: preloadedShortcomings || [],
      choice: null,
      automaticDateEnd: typeof automaticDateEnd == 'boolean' ? automaticDateEnd : true,
      author: author || null,
    }

    return data
  }, [profile, nextNumber, prescriptionData])

  const isDirtyWithoutStatusAndDateDone = (values: PrescriptionFormData): boolean => {
    const { status, dateDone, ...localInitialValues }: Partial<PrescriptionFormData> = { ...initialValues }
    const {
      status: statusValue,
      dateDone: dateDoneValue,
      ...localValues
    }: Partial<PrescriptionFormData> = { ...values }

    return !isEqual(localInitialValues, localValues)
  }

  const onSubmit = useCallback(
    (values: PrescriptionFormData, isShouldCheckNumberForUniqueness: boolean, isShouldDoNotificationQuery: boolean) => {
      const { status, dateDone } = values

      if (dateDone && status !== 'COMPLETE') {
        showSnackbar(
          'Предписание имеет факт. дату устранения. Измените статус на Исполнено, либо удалите факт. дату устранения.',
          { variant: 'error' },
        )

        return
      }

      if (
        prescriptionId &&
        isDirtyWithoutStatusAndDateDone(values) &&
        initialValues.status !== 'CREATED' &&
        values.status !== 'CREATED'
      ) {
        showSnackbar('Изменение данных в предписании возможно только в статусе Создано.', { variant: 'error' })
        return
      }

      if (isShouldCheckNumberForUniqueness && values.number !== initialValues.number) {
        checkNumberForUniqueness({ projectId, prescriptionNumber: values.number })

        return
      }

      const dataForRequest = getDataForRequest(values, initialValues)

      if (isShouldDoNotificationQuery && prescriptionId) {
        checkPrescriptionForEmailNotification({
          projectId,
          prescriptionId: prescriptionId,
          body: dataForRequest,
        })

        return
      }
      setNewRemarkId(null)

      if (prescriptionId) {
        if (values.choice) {
          console.log('bind remarks start')
          bindRemarks({
            projectId,
            prescriptionId,
            // atomic: data.atomic.addShortcomings,
            body: values.choice,
          })
        }

        if (values.filesForCreate.length) {
          uploadFilesToPrescription(prescriptionId)
        }

        if (values.filesIdsToDelete.length) {
          deleteFilesFromPrescription()
        }

        if (values.filesForCreate.length || values.filesIdsToDelete.length || values.choice) return

        onEditPrescription()
      } else {
        console.log('create prescription start')

        createPrescription({ projectId, body: dataForRequest })
      }
    },
    [initialValues, isDirtyWithoutStatusAndDateDone, isShouldSendNotification],
  )

  const onEditPrescription = () => {
    console.log('edit prescription start')
    if (prescriptionId) {
      editPrescription({
        projectId,
        prescriptionId,
        body: getDataForRequest(values, initialValues),
        sort: sortableColumn,
        filter: filterData,
        notification: isShouldSendNotification,
      })
    }
  }

  const handlePrescriptionCreating = (invalidateTags: TTagType[]) => {
    navigate(`/project/${projectId}/prescriptions/edit/${newRemarkId}`)
    dispatch(prescriptionsApi.util.invalidateTags(invalidateTags))
    setIsShouldSendNotification(false)
    setIsFormLoading(false)
  }

  const { formik } = useForm({
    validationSchema: validationPrescription,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      onSubmit(values, true, !!isShouldDoNotificationQuery)
    },
  })

  const { values, setFieldValue, dirty, isValid, setValues, setTouched, resetForm } = formik

  const [isLocalValid, setIsLocalValid] = useState<boolean>(isValid)
  const isShouldDoNotificationQuery =
    prescriptionId && values.status !== 'CREATED' && initialValues.status !== values.status
  const [isDataSinchronized, setIsDataSinchronized] = useState(false)
  const isDataSinchronizedSaved = useMemo(() => isDataSinchronized, [isDataSinchronized])
  useEffect(() => {
    if (
      !isDataSinchronizedSaved &&
      prescriptionValues &&
      !isPrescriptionLoading &&
      !isPrescriptionNumberLoading &&
      !isEqual(prescriptionValues, values)
    ) {
      setValues(prescriptionValues)
      setIsDataSinchronized(true)
    }
  }, [prescriptionValues, isPrescriptionLoading, isPrescriptionNumberLoading])

  useEffect(() => {
    if (values.executionControl && values.receiver) setIsLocalValid(true)
    else setIsLocalValid(false)
  }, [values])

  const { data: getBindRemarksResponse, isLoading } = useGetBindRemarksQuery(
    {
      projectId,
      body: { choice: values.choice! },
      prescriptionId,
      chosen: true,
    },
    { skip: !values.choice },
  )

  useEffect(() => {
    if (!getBindRemarksResponse) return

    const remarks =
      getBindRemarksResponse?.data?.reduce<RemarkFull[]>((remarks, remarkData) => {
        remarks.push(remarkData.shortcoming)
        return remarks
      }, []) || []

    setFieldValue('remarks', remarks)

    if (values.automaticDateEnd && getBindRemarksResponse?.deadline) {
      setFieldValue('dateEnd', parseResponseDate(getBindRemarksResponse?.deadline).date)
      setTouched({ dateEnd: true })
    }
  }, [getBindRemarksResponse])

  // useEffect(() => {
  //   if (isBoundRemarksSuccess) {
  //     const remarks =
  //       boundRemarksData?.data?.reduce<RemarkFull[]>((remarks, remarkData) => {
  //         remarks.push(remarkData.shortcoming)
  //         return remarks
  //       }, []) || []
  //     setFieldValue('remarks', remarks)

  //     if (values.automaticDateEnd) {
  //       setFieldValue('dateEnd', parseResponseDate(boundRemarksData?.deadline).date)
  //       setTouched({ dateEnd: true })
  //     }
  //   }
  // }, [boundRemarksData])

  const handleExitConfirm = useCallback((confirm: boolean) => {
    if (confirm) onReturnClick()
  }, [])

  const handleDeleteConfirm = useCallback(
    (confirm: boolean) => {
      if (confirm && prescriptionId) {
        deletePrescription({ projectId, prescriptionId })
      }
    },
    [values],
  )

  const handleCloseDrawer = useCallback((confirm: boolean) => {
    if (confirm) setOpenedDrawer(null)
  }, [])

  const handleNotificationConfirm = useCallback(
    (confirm: boolean) => {
      if (confirm) setIsShouldSendNotification(confirm)
      else onSubmit(values, false, false)
    },
    [values],
  )

  useEffect(() => {
    if (isShouldSendNotification) onSubmit(values, false, false)
  }, [isShouldSendNotification])

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<PrescriptionFormDialogTrigger>('exit')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    exit: { handleConfirm: handleExitConfirm },
    delete: {
      handleConfirm: handleDeleteConfirm,
      title: 'Удалить предписание?',
      body: 'Предписание будет удалено.',
    },
    closeBind: { handleConfirm: handleCloseDrawer },
    notification: {
      handleConfirm: handleNotificationConfirm,
      title: 'Отправить уведомление?',
      body: (
        <Typography variant='body2' color={theme.palette.text.dark}>
          Уведомление о смене статуса будет направлено на e-mail представителей подрядчика.
        </Typography>
      ),
      denyButtonText: 'нет',
    },
    eliminationResult: { handleConfirm: handleCloseDrawer },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  const onReturn = useCallback(
    (dirty: boolean, immediately?: boolean) => {
      setConfirmDialogTrigger('exit')
      let localDirty = dirty

      if (Object.values(autofilledData).some((autofilledItem) => autofilledItem)) {
        const localInitialValues = initialValues

        for (let key in autofilledData) {
          // @ts-ignore
          if (!localInitialValues[key]) localInitialValues[key] = autofilledData[key]
        }

        localDirty = !isEqual(localInitialValues, values)
      }

      immediately || !localDirty ? onReturnClick() : openConfirm()
    },
    [autofilledData, values],
  )

  const onDelete = useCallback(() => {
    setConfirmDialogTrigger('delete')
    openConfirm()
  }, [])

  const uploadFilesToPrescription = (id: number) => {
    console.log('import files start')
    uploadFilesToPrescriptionRequest({ projectId, prescriptionId: id, files: [...values.filesForCreate].reverse() })
  }

  const deleteFilesFromPrescription = () => {
    console.log('delete files start')
    prescriptionId &&
      deleteFilesFromPrescriptionRequest({
        projectId,
        prescriptionId,
        filesIds: values.filesIdsToDelete,
      })
  }

  const formPrescription = () => {
    setIsPrescriptionForming(true)
    prescriptionId && formPrescriptionRequest({ projectId, prescriptionId })
  }

  const handleError = useCallback((snackbarText: string | boolean = 'Произошла ошибка.') => {
    snackbarText && showSnackbar(snackbarText, { variant: 'error' })
    setIsFormLoading(false)
  }, [])

  const [openedDrawer, setOpenedDrawer] = useState<PrescriptionDrawerType | null>(null)

  const onDrawerOpen = useCallback((drawerType: PrescriptionDrawerType) => {
    setOpenedDrawer(drawerType)
  }, [])

  const onDrawerClose = useCallback(
    (dialogType: PrescriptionFormDialogTrigger, dirty: boolean, immediately?: boolean) => {
      if (immediately || !dirty) setOpenedDrawer(null)
      else {
        setConfirmDialogTrigger(dialogType)
        openConfirm()
      }
    },
    [],
  )

  useMutationHandlers(
    checkNumberForUniquenessResponse,
    (data) => {
      if (!data) onSubmit(values, false, !!isShouldDoNotificationQuery)
      else showSnackbar('Предписание с данным номером уже существует.', { variant: 'error' })

      if (!!isShouldDoNotificationQuery || data) setIsFormLoading(false)
    },
    () => handleError('Произошла ошибка при проверке уникальности номера предписания.'),
  )

  useMutationHandlers(
    checkEmailNotificationResult,
    (data) => {
      if (data.data) {
        setConfirmDialogTrigger('notification')
        openConfirm()
      } else {
        onSubmit(values, false, false)
      }
    },
    () => handleError('Произошла ошибка при проверке условий для отправления уведомления.'),
  )

  useMutationHandlers(
    createPrescriptionResponse,
    (data) => {
      if (!data.success) {
        showSnackbar(data.description, { variant: 'error' })
        return
      }
      enqueueSnackbar({ message: 'Предписание успешно добавлено.', variant: 'success' })

      console.log('create prescription end')
      if (values.choice) {
        console.log('bind remark start')
        bindRemarks({
          projectId,
          prescriptionId: data.data.id,
          body: { ...values.choice, all: false },
        })
      }

      if (values.filesForCreate.length) uploadFilesToPrescription(data.data.id)

      if (!values.choice && !values.filesForCreate.length) {
        console.log('snack + nav + cache after create prescription end')
        dispatch(prescriptionsApi.util.invalidateTags(['Prescriptions', 'ProjectsDashboard']))

        navigate(`/project/${projectId}/prescriptions/edit/${data.data.id}`)

        setIsFormLoading(false)
      }

      setNewRemarkId(data.data.id)
    },
    () => handleError('Произошла ошибка при создании предписания.'),
  )

  useMutationHandlers(
    editPrescriptionResponse,
    (data) => {
      if (!data.success) {
        showSnackbar(data.description, { variant: 'error' })
        return
      }
      enqueueSnackbar({ message: 'Предписание успешно изменено.', variant: 'success' })

      console.log('edit prescription end')
      console.log('snack + cache after edit prescription end')

      dispatch(prescriptionsApi.util.invalidateTags(['Remarks', { type: 'Prescriptions', id: 'PRESCRIPTION_BY_ID' }]))
      setIsShouldSendNotification(false)
      setIsFormLoading(false)
      resetForm()
    },
    () => {
      enqueueSnackbar({ message: 'Произошла ошибка при изменении предписания.', variant: 'error' })

      dispatch(prescriptionsApi.util.invalidateTags(['Remarks', { type: 'Prescriptions', id: 'PRESCRIPTION_BY_ID' }]))
      setIsShouldSendNotification(false)
      setIsFormLoading(false)
      resetForm()
    },
  )

  useMutationHandlers(
    uploadFilesToPrescriptionResponse,
    (data) => {
      if (!data.success) {
        showSnackbar(data.description, { variant: 'error' })
        return
      }
      enqueueSnackbar({ message: 'Файлы успешно добавлены.', variant: 'success' })

      console.log('import files end')
      if (!isFilesDeleting && !isBindRemarksLoading) {
        if (!prescriptionId) handlePrescriptionCreating(['Prescriptions'])
        else onEditPrescription()
      }
    },
    () => {
      enqueueSnackbar({ message: 'Произошла ошибка при прикреплении файлов.', variant: 'error' })

      if (!isFilesDeleting && !isBindRemarksLoading) {
        if (!prescriptionId) handlePrescriptionCreating(['Prescriptions'])
        else onEditPrescription()
      }
    },
  )

  useMutationHandlers(
    deleteFilesFromPrescriptionResponse,
    (data) => {
      if (!data.success) {
        showSnackbar(data.description, { variant: 'error' })
        return
      }
      enqueueSnackbar({ message: 'Файлы успешно удалены.', variant: 'success' })

      console.log('delete files end')
      if (!isFilesUploading && !isBindRemarksLoading) onEditPrescription()
    },
    () => {
      enqueueSnackbar({ message: 'Произошла ошибка при удалении файлов.', variant: 'error' })

      if (!isFilesUploading && !isBindRemarksLoading) onEditPrescription()
    },
  )

  useMutationHandlers(
    deletePrescriptionResponse,
    (data) => {
      if (!data.success) {
        showSnackbar(data.description, { variant: 'error' })
        return
      }
      showSnackbar('Предписание успешно удалено.', { variant: 'success' })
      onReturnClick(false)
    },
    () => handleError('Произошла ошибка при удалении предписания.'),
  )

  useMutationHandlers(
    bindRemarksResponse,
    (data) => {
      if (!data.success) {
        showSnackbar(data.description, { variant: 'error' })
        return
      }
      enqueueSnackbar({ message: 'Прикреплённые замечания успешно изменены.', variant: 'success' })

      console.log('bind remark end')
      if (!isFilesUploading && !isFilesDeleting) {
        if (!prescriptionId) handlePrescriptionCreating(['Prescriptions', 'Remarks'])
        else onEditPrescription()
      }
    },
    () => {
      enqueueSnackbar({ message: 'Произошла ошибка при изменении прикреплённых замечаний.', variant: 'error' })

      if (!isFilesUploading && !isFilesDeleting) {
        if (!prescriptionId) handlePrescriptionCreating(['Prescriptions', 'Remarks'])
        else onEditPrescription()
      }
    },
  )

  useEffect(() => {
    const { status, data } = formPrescriptionResult

    if (status === 'fulfilled' && data && isPrescriptionForming) {
      downloadMedia(data.link)
      showSnackbar('Файл успешно сгенерирован.', { variant: 'success' })
      setIsPrescriptionForming(false)
    }
    if (status === 'rejected') {
      showSnackbar('Произошла ошибка при формировании предписания.', { variant: 'error' })
      setIsPrescriptionForming(false)
    }
  }, [formPrescriptionResult])

  useEffect(() => {
    const { status, data } = checkForFilterSatisfactionResult

    if (status === 'fulfilled' && data) onReturnClick()
  }, [checkForFilterSatisfactionResult])

  const onReturnClick = (isShouldCheckFilterSatisfaction: boolean = true) => {
    const appliedFilter = Object.values(filterData || {}).some((filterableValue) => filterableValue) ? filterData : null

    if (
      appliedFilter &&
      prescriptionId &&
      checkForFilterSatisfactionResult.status !== 'fulfilled' &&
      isShouldCheckFilterSatisfaction &&
      !backUrl
    ) {
      checkForFilterSatisfaction({
        projectId,
        prescriptionId,
        filter: appliedFilter,
        sort: sortableColumn,
      })
    } else {
      navigate(backUrl || `/project/${projectId}/prescriptions`, {
        state: backState || {
          sortableColumn,
          filterData,
          prescriptionId,
          prescriptionsPage: currentPage,
          transaction: true,
        },
      })
    }
  }

  return (
    <>
      {prescriptionId && (isPrescriptionDataFetching || isNextNumberFetching) ? (
        <Progress />
      ) : (
        <Stack width='100%' bgcolor={theme.palette.bg.white}>
          <SimpleHeader
            title={((viewingOnly && 'Просмотр ') || (prescriptionId ? 'Редактирование ' : 'Создание ')) + 'предписания'}
            onClose={() => onReturn(dirty, false)}
          >
            <Stack direction='row' spacing={1.25}>
              {formMode === 'edit' && (
                <Button disabled={dirty} size='medium' onClick={() => onDrawerOpen('eliminationResult')}>
                  Результат устранения
                </Button>
              )}
              {!!prescriptionId && <AuditButton onButtonClick={() => onDrawerOpen('audit')} />}
            </Stack>
          </SimpleHeader>
          <FormWrapper>
            <FormikProvider value={formik}>
              <Stack component={Form}>
                <FullWrapper>
                  <FormItemsWrapper>
                    <Info
                      viewingOnly={viewingOnly}
                      formMode={formMode}
                      isStatusChangeAvailable={!!isStatusChangeAvailable}
                    />

                    <Control
                      viewingOnly={viewingOnly}
                      setIsLocalValid={setIsLocalValid}
                      formMode={formMode}
                      setAutofilledData={setAutofilledData}
                    />

                    <Remarks
                      viewingOnly={viewingOnly}
                      onBindDrawerOpen={() => onDrawerOpen('remarks')}
                      currentPage={currentPage}
                      savedLocationState={savedLocationState}
                    />
                  </FormItemsWrapper>

                  <Stack direction='row' spacing={2} justifyContent='flex-end' paddingRight={1.5}>
                    {!viewingOnly && (
                      <>
                        {prescriptionId ? (
                          <>
                            <Button onClick={onDelete} icon={true} size='medium' color='error' variant='outlined'>
                              <DeleteIcon style={{ fill: theme.palette.error.main }} />
                              <Typography color={theme.palette.error.main} marginLeft={1}>
                                Удалить предписание
                              </Typography>
                            </Button>
                            <Button
                              onClick={formPrescription}
                              disabled={dirty || (!isValid && !isLocalValid) || isFormLoading}
                              loading={isPrescriptionForming}
                              color='success'
                              style={{ maxWidth: '100%' }}
                            >
                              Сформировать предписание
                            </Button>
                          </>
                        ) : null}

                        <Button
                          type='submit'
                          disabled={!dirty || !isValid || !isLocalValid}
                          loading={isFormLoading}
                          color='success'
                          size='medium'
                        >
                          Сохранить
                        </Button>
                      </>
                    )}
                    <Button size='medium' onClick={() => onReturn(dirty, false)}>
                      Закрыть
                    </Button>
                  </Stack>
                </FullWrapper>
              </Stack>

              <BindRemarksDrawer
                open={openedDrawer === 'remarks'}
                onClose={(dirty, immediately) => onDrawerClose('closeBind', dirty, immediately)}
                rootChoice={values.choice}
              />
            </FormikProvider>
          </FormWrapper>
        </Stack>
      )}

      <ConfirmDialog />

      <EliminationResultDrawer
        isOpen={openedDrawer === 'eliminationResult'}
        onClose={(dirty, immediately) => onDrawerClose('eliminationResult', dirty, immediately)}
        prescriptionDateStart={values.dateStart}
        prescriptionStatus={initialValues.status}
      />

      <AuditDrawer
        variant='prescriptions'
        openedAuditId={openedDrawer === 'audit' ? prescriptionId! : null}
        onClose={() => onDrawerClose('exit', false, true)}
        key={projectId + 'prescriptions' + (prescriptionId ?? '')}
      />
    </>
  )
}
