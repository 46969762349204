import { AnalyticsWrapper, GroupWidgets, WidgetsWrapper } from './Analytics.styles'
import { AnalyticsDialogTrigger, AnalyticsDrawerType, AnalyticsQueryData } from './Analytics.types'
import { AnalyticsFilterDrawer } from './components/AnalyticsFilterDrawer'
import { AnalyticsFilterLegend } from './components/AnalyticsFilterLegend'
import { DynamicWidget } from './widgets/DynamicWidget'
import { GeneralWidget } from './widgets/GeneralWidget'
import { ProjectWidget } from './widgets/ProjectWidget'
import { StatisticalWidget } from './widgets/StatisticalWidget'
import { useGetAnalyticsSettingsQuery } from 'api/analytics'
import { uiContext } from 'contexts/ui'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { FC, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { setAnalyticsSettings } from 'store/slices/analytics'
import { analyticsSettingsSelector, isAnalyticsSettingsSynchronizedSelector } from 'store/slices/analytics/selectors'
import { useAppDispatch, useTypedSelector } from 'store/store'

export const Analytics: FC = () => {
  useBreadcrumbs([{ title: 'Аналитика' }])
  const { data: analyticsSettings } = useGetAnalyticsSettingsQuery()
  const dispatch = useAppDispatch()
  const cUI = useContext(uiContext)
  const [openedDrawer, setOpenedDrawer] = useState<AnalyticsDrawerType | null>(null)

  const analyticsSettingsBySelector = useTypedSelector(analyticsSettingsSelector)
  const isAnalyticsSettingsSynchronized = useTypedSelector(isAnalyticsSettingsSynchronizedSelector)

  const { filters } = analyticsSettingsBySelector || {}
  const { after, before, projects, contractors, directions } = filters || {}

  const analyticsQueryData = useMemo((): AnalyticsQueryData => {
    const localParams = { after, before }

    for (let key in localParams) {
      const typedKey = key as keyof typeof localParams
      if (!localParams[typedKey]) {
        delete localParams[typedKey]
      }
    }

    const queryData: AnalyticsQueryData = {
      queryBody: {
        params: localParams,
        body: {
          deviation: null,
          projects,
          contractors,
          directions,
        },
      },
      skip: { skip: !isAnalyticsSettingsSynchronized },
    }

    if (!Object.values(localParams).filter((item) => item).length) {
      delete queryData.queryBody.params
    }

    return queryData
  }, [filters])

  useLayoutEffect(() => {
    cUI.setShowSearch(false)
    return () => {
      cUI.setShowSearch(true)
    }
  }, [])

  useEffect(() => {
    if (analyticsSettings) dispatch(setAnalyticsSettings(analyticsSettings.data))
  }, [analyticsSettings])

  const onDrawerOpen = (drawerType: AnalyticsDrawerType) => {
    setOpenedDrawer(drawerType)
  }

  const onDrawerClose = useCallback((dialogType: AnalyticsDialogTrigger, dirty: boolean, immediately?: boolean) => {
    if (immediately || !dirty) setOpenedDrawer(null)
    else {
      setConfirmDialogTrigger(dialogType)
      openConfirm()
    }
  }, [])

  const onFilterDrawerOpen = useCallback(() => {
    onDrawerOpen('filter')
  }, [])

  const handleExitConfirm = useCallback((confirm: boolean) => {
    if (confirm) setOpenedDrawer(null)
  }, [])

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<AnalyticsDialogTrigger>('filterExit')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    filterExit: { handleConfirm: handleExitConfirm },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  return (
    <AnalyticsWrapper spacing={2}>
      <AnalyticsFilterLegend onFilterDrawerOpen={onFilterDrawerOpen} />

      <WidgetsWrapper spacing={2.5}>
        <GroupWidgets>
          <DynamicWidget analyticsQueryData={analyticsQueryData} />
          <GeneralWidget analyticsQueryData={analyticsQueryData} />
        </GroupWidgets>
        <GroupWidgets>
          <StatisticalWidget analyticsQueryData={analyticsQueryData} />
          <ProjectWidget analyticsQueryData={analyticsQueryData} />
        </GroupWidgets>
      </WidgetsWrapper>

      <AnalyticsFilterDrawer
        isOpen={openedDrawer === 'filter'}
        onClose={(dirty, immediately) => onDrawerClose('filterExit', dirty, immediately)}
      />

      <ConfirmDialog />
    </AnalyticsWrapper>
  )
}
