import { CustomColumnDef } from '../../../Remarks/components/RemarksTable'
import { DateDoneCell } from '../../../Remarks/components/RemarksTable/cells/DateDoneCell'
import { StatusCell, StatusCellMenuItemProps } from '../../../Remarks/components/RemarksTable/cells/StatusCell'
import { Prescription } from 'api/prescriptions/types'
import { ContractorRespUser, ResponsibleRepresentative } from 'api/projects/types'
import { RemarkAuthor } from 'api/remarks/types'
import {
  PrescriptionFilterableColumn,
  FilterRange,
  PrescriptionInspection,
  prescriptionInspectionRuByEn,
  PrescriptionStatus,
  prescriptionStatusEnByRu,
  prescriptionStatuses,
  prescriptionStatusesColor,
  prescriptionStatusRuByEn,
  PrescriptionType,
  prescriptionTypeRuByEn,
  SortableColumn,
  SortingOrder,
  prescriptionDirectionRuByEn,
  TPrescriptionDirection,
} from 'core/types/prescription'
import { CountCell } from 'pages/Remarks/components/RemarksTable/cells/CountCell'
import { getPrescriptionCountCellData } from 'pages/Remarks/components/RemarksTable/cells/CountCell/CountCell.types'
import React from 'react'
import { connectNames } from 'utils/connectNames'
import { parseResponseDate } from 'utils/dates/parseResponseDate'

export interface PrescriptionsTableProps {
  // locationState: PrescriptionLocationState
  sort: SortData
  filter: FilterData
}

export interface SortData {
  sortableColumn: CurrentSortableColumn
  setSortableColumn: (column: CurrentSortableColumn) => void
}

export interface FilterData {
  filterData: PrescriptionFilters
  setFilterData: (filters: PrescriptionFilters) => void
}

export interface CurrentSortableColumn {
  column: SortableColumn | null
  order: SortingOrder | null
}

export interface PrescriptionFilters
  extends Record<PrescriptionFilterableColumn, string[] | number[] | FilterRange | null> {
  status: string[] | null
  contractor: number[] | null
  sender_company: string[] | null
  sender_user: string[] | null
  period_dateStart: string[] | null
  period_dateStart_range: FilterRange | null
  period_dateEnd: string[] | null
  period_dateEnd_range: FilterRange | null
  period_dateDone: string[] | null
  period_dateDone_range: FilterRange | null
  type: string[] | null
  author: string[] | null
  receiver: string[] | null
  execution_control: string[] | null
  number: string[] | null
}

// Columns
export const numberColumn: CustomColumnDef<Prescription> = {
  accessorKey: 'number',
  header: '№',
  minSize: 170,
  size: 170,
  maxSize: 170,
}

export const getStatusColumn = (
  onStatusSelect: (projectId: number, prescriptionId: number, status: PrescriptionStatus, dateDone: string) => void,
): CustomColumnDef<Prescription> => ({
  accessorKey: 'status',
  header: 'Статус',
  minSize: 180,
  size: 180,
  maxSize: 180,
  cell: (info) => {
    const status = info.getValue<PrescriptionStatus>()
    const { period, canEditStatus } = info.row.original

    const statusCellMenuItemsData: StatusCellMenuItemProps<PrescriptionStatus>[] = prescriptionStatuses
      .filter((menuStatus) => menuStatus !== prescriptionStatusRuByEn[status])
      .map((menuStatus) => {
        const valueForOnClick = prescriptionStatusEnByRu[menuStatus]
        const checked = status === valueForOnClick

        return {
          value: menuStatus,
          valueForOnClick,
          checked: checked,
          key: menuStatus,
          children: menuStatus,
        }
      })

    return (
      <StatusCell
        variant='prescriptions'
        info={info}
        onSelect={(projectId, remarkId, status) => onStatusSelect(projectId, remarkId, status, period?.dateDone)}
        itemsData={statusCellMenuItemsData}
        statusData={{
          body: prescriptionStatusRuByEn[status],
          color: prescriptionStatusesColor[status],
        }}
        editingAvailable={canEditStatus}
      />
    )
  },
  isCustomCell: true,
})

export const directionColumn: CustomColumnDef<Prescription> = {
  accessorKey: 'direction',
  header: 'Вид предписания',
  minSize: 200,
  size: 200,
  maxSize: 220,
  textAlign: 'left',
  cell: (info) => {
    const value = info.getValue<TPrescriptionDirection>()
    return prescriptionDirectionRuByEn[value]
  },
}

export const typeColumn: CustomColumnDef<Prescription> = {
  id: 'type',
  accessorKey: 'type',
  header: 'Тип предписания',
  minSize: 200,
  size: 200,
  maxSize: 220,
  textAlign: 'left',
  cell: (info) => {
    const value = info.getValue<PrescriptionType>()
    return prescriptionTypeRuByEn[value]
  },
}

export const inspectionTypeColumn: CustomColumnDef<Prescription> = {
  accessorKey: 'inspectionType',
  header: 'Вид обследования',
  minSize: 170,
  size: 170,
  maxSize: 170,
  cell: (info) => {
    const value = info.getValue<PrescriptionInspection>()
    return prescriptionInspectionRuByEn[value]
  },
  textAlign: 'left',
}

export const dateStartColumn: CustomColumnDef<Prescription> = {
  accessorKey: 'period.dateStart',
  header: 'Дата предписания',
  minSize: 200,
  size: 200,
  maxSize: 200,
  cell: (info) => {
    const value = info.getValue<string>()
    return parseResponseDate(value).fullDate
  },
}

export const dateEndColumn: CustomColumnDef<Prescription> = {
  accessorKey: 'period.dateEnd',
  header: 'Устан. дата устранения',
  minSize: 180,
  size: 180,
  maxSize: 180,
  cell: (info) => <DateDoneCell info={info} dateVariant='end' splitDeviation />,
  isCustomCell: true,
}

export const dateDoneColumn: CustomColumnDef<Prescription> = {
  accessorKey: 'period.dateDone',
  header: 'Факт. дата устранения',
  minSize: 180,
  size: 180,
  maxSize: 180,
  cell: (info) => <DateDoneCell info={info} dateVariant='done' splitDeviation />,
  isCustomCell: true,
}

export const commentColumn: CustomColumnDef<Prescription> = {
  accessorKey: 'comment',
  header: 'Комментарий',
  minSize: 270,
  size: 270,
  maxSize: 270,
  textAlign: 'left',
}

export const authorColumn: CustomColumnDef<Prescription> = {
  accessorKey: 'author',
  header: 'Автор',
  minSize: 300,
  size: 300,
  textAlign: 'left',
  cell: (info) => {
    const author = info.getValue<RemarkAuthor>()
    return connectNames(author)
  },
}

export const contractorColumn: CustomColumnDef<Prescription> = {
  accessorKey: 'contractor',
  header: 'Организация, получившая предписание',
  minSize: 260,
  size: 260,
  cell: (info) => {
    const { shortName } = info.getValue<ContractorRespUser>()
    return shortName || '-'
  },
  textAlign: 'left',
}

export const senderCompanyColumn: CustomColumnDef<Prescription> = {
  accessorKey: 'sender_company',
  header: 'Организация, выдавшая предписание',
  minSize: 260,
  size: 260,
  cell: (info) => {
    const { company, projectMember } = info.row.original.senderCompany
    return company || projectMember?.shortName
  },
  textAlign: 'left',
}

export const senderUserColumn: CustomColumnDef<Prescription> = {
  accessorKey: 'sender_user',
  header: 'Предписание выдал',
  minSize: 260,
  size: 260,
  cell: (info) => {
    const { representative, user } = info.row.original.senderUser
    return representative?.fullName || connectNames(user)
  },
  textAlign: 'left',
}

export const receiverColumn: CustomColumnDef<Prescription> = {
  accessorKey: 'receiver',
  header: 'Предписание получил',
  minSize: 260,
  size: 260,
  cell: (info) => {
    const { fullName } = info.getValue<ResponsibleRepresentative>()
    return fullName || '-'
  },
  textAlign: 'left',
}

export const executionControlColumn: CustomColumnDef<Prescription> = {
  accessorKey: 'execution_control',
  header: 'Контроль за устранением нарушений',
  minSize: 260,
  size: 260,
  cell: (info) => {
    const { fullName } = info.row.original.executionControl
    return fullName || '-'
  },
  textAlign: 'left',
}

export const countColumn: CustomColumnDef<Prescription> = {
  id: 'count',
  minSize: 168,
  size: 168,
  maxSize: 168,
  isCustomCell: true,
  cell: (info) => <CountCell data={getPrescriptionCountCellData(info)} />,
}

export interface PrescriptionNotificationData {
  status: PrescriptionStatus
  prescriptionId: number
  dateDone: string | null
}

export type PrescriptionTableDialogTrigger = 'notification'
