import { SnackbarMessage, useSnackbar as useSnackbarNotistack, VariantType } from 'notistack'
import { useEffect, useState } from 'react'

export interface ISnackbarData {
  message: SnackbarMessage
  variant?: VariantType
}

export interface IUseSnackbarProps {
  snackbarTrigger: any
  fullSuccessText: string
}

export const useSnackbar = ({ snackbarTrigger, fullSuccessText }: IUseSnackbarProps) => {
  const [snackbarData, setSnackbarData] = useState<ISnackbarData[]>([])
  const { enqueueSnackbar: showSnackbar } = useSnackbarNotistack()

  useEffect(() => {
    if (!snackbarTrigger && snackbarData.length) {
      const countOfFail: number = snackbarData.filter((item) => item.variant === 'error').length
      if (!countOfFail)
        showSnackbar(fullSuccessText, {
          variant: 'success',
        })
      else if (countOfFail === snackbarData.length) showSnackbar('Произошла ошибка.', { variant: 'error' })
      else {
        snackbarData.map((data) =>
          showSnackbar(data.message, { variant: data.variant, autoHideDuration: 1500 * snackbarData.length }),
        )
      }
      setSnackbarData([])
    }
  }, [snackbarTrigger])

  const enqueueSnackbar = (data: ISnackbarData) => {
    setSnackbarData((p) => [...p, data])
  }

  return { showSnackbar, enqueueSnackbar }
}
