import { PrescriptionFormData, PrescriptionLocationState } from '../../../../PrescriptionsForm.types'
import { RemarkItemWrapper, Description, Image, FileWrapper, IconWrapper } from './RemarkItem.styles'
import { RemarkItemProps } from './RemarkItem.types'
import { InsertDriveFile as FileIcon, Edit as EditIcon, Visibility as ViewingIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { remarkStatusesClient, remarkStatusesColor } from 'api/remarks/types'
import { ColoredTitle } from 'components/ColoredTitle'
import { Tooltip } from 'components/Tooltip'
import { eliminationStatusRuByEn, eliminationStatusesColor } from 'core/types/elimination'
import { useFormikContext } from 'formik'
import { EliminationStatusMarker } from 'pages/Remarks/components/EliminationDrawer/components/EliminationForm/EliminationForm.styles'
import { StatusCellContent } from 'pages/Remarks/components/RemarksTable/cells/StatusCell'
import { FC, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'
import { parseResponseDate } from 'utils/dates/parseResponseDate'

export const RemarkItem: FC<RemarkItemProps> = ({ remark, remove, currentPage, savedLocationState, onViewerOpen }) => {
  const profile = useTypedSelector(profileSelector)
  const viewingOnly: boolean = profile.role === 'client' || profile.role === 'contractor'
  const { values, setFieldValue, dirty } = useFormikContext<PrescriptionFormData>()
  const dateEnd = parseResponseDate(remark.period.dateEnd).fullDate
  const dateDone = parseResponseDate(remark.period.dateDone).fullDate
  const displayedImages: number = 5
  const navigate = useNavigate()
  const { projectId: projectIdString, prescriptionId: prescriptionIdString } = useParams()
  const projectId = Number(projectIdString)
  const prescriptionId = Number(prescriptionIdString)
  const [viewedImageSource, setViewedImageSource] = useState<string | null>(null)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const { sortableColumn, filterData } = savedLocationState || {}

  const ColoredStatus = (
    <ColoredTitle body={remarkStatusesClient[remark.status]} color={remarkStatusesColor[remark.status]} />
  )

  const onNavigateToRemark = (forceCondition?: boolean) => {
    if (dirty) return
    // if (dirty && !forceCondition) {
    //   setConfirmDialogTrigger('editRemark');
    //   openConfirm()
    //   return;
    // }

    navigate(`/project/${projectId}/remarks/edit/${remark.id}`, {
      state: { sortableColumn, filterData, prescriptionValues: { id: prescriptionId }, prescriptionsPage: currentPage },
    })
    // navigate(`/project/${projectId}/remarks/edit/${remark.id}`, { state: { sortableColumn, filterData, prescriptionValues: { ...values, id: prescriptionId }, prescriptionsPage: currentPage } })
  }

  const isPdfFile = (key: string) => {
    let isPdf: any = key.split('.')
    isPdf = isPdf[isPdf.length - 1].toLowerCase() === 'pdf'
    return isPdf
  }

  const renderDeviation = () => {
    let today = new Date()
    today = new Date(today.setHours(0, 0, 0, 0))

    const dateEnd = new Date(remark.period.dateEnd)
    let daysDeviation = 0

    if (remark.period.dateDone) {
      const dateDone = new Date(remark.period.dateDone)

      if (dateEnd < dateDone)
        daysDeviation = Math.ceil(Math.abs(dateDone.getTime() - dateEnd.getTime()) / (1000 * 3600 * 24))
    } else if (dateEnd < today) {
      daysDeviation = Math.ceil(Math.abs(today.getTime() - dateEnd.getTime()) / (1000 * 3600 * 24))
    }

    return (
      daysDeviation > 0 && (
        <Typography variant='body1' color='error' textAlign='start' fontSize='12px' lineHeight='100%'>
          + {daysDeviation} дн.
        </Typography>
      )
    )
  }

  return (
    <RemarkItemWrapper spacing='10px'>
      <Stack direction='row' justifyContent='space-between'>
        <Stack>
          <Stack>
            <Stack direction='row'>
              <Typography variant='subtitle1' color={theme.palette.text.dark}>
                Замечание №{remark.number}
              </Typography>

              <Tooltip
                title={viewingOnly ? 'Перейти к просмотру замечания' : 'Перейти к редактированию замечания'}
                bgColor={theme.palette.bg.white}
                textColor={theme.palette.text.dark}
              >
                <IconWrapper onClick={() => onNavigateToRemark()} isDisabled={dirty}>
                  {viewingOnly ? (
                    <ViewingIcon fontSize='small' color='primary' />
                  ) : (
                    <EditIcon fontSize='small' color='primary' />
                  )}
                </IconWrapper>
              </Tooltip>
            </Stack>
            <Stack spacing={1.25}>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography
                  variant='body2'
                  color={theme.palette.secondary.gray}
                  textAlign='start'
                  fontSize='12px'
                  lineHeight='100%'
                >
                  Статус:
                </Typography>
                <StatusCellContent display='flex' alignItems='center'>
                  {ColoredStatus}
                </StatusCellContent>
              </Stack>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography
                  variant='body1'
                  color={theme.palette.secondary.gray}
                  textAlign='start'
                  fontSize='12px'
                  lineHeight='100%'
                >
                  Устан. дата устранения:
                </Typography>
                <Typography
                  variant='subtitle2'
                  color={theme.palette.text.dark}
                  textAlign='start'
                  fontSize='12px'
                  lineHeight='100%'
                >
                  {dateEnd}
                </Typography>
                {!dateDone && renderDeviation()}
              </Stack>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography
                  variant='body1'
                  color={theme.palette.secondary.gray}
                  textAlign='start'
                  fontSize='12px'
                  lineHeight='100%'
                >
                  Факт. дата устранения:
                </Typography>
                <Typography
                  variant='subtitle2'
                  color={theme.palette.text.dark}
                  textAlign='start'
                  fontSize='12px'
                  lineHeight='100%'
                >
                  {dateDone || '—'}
                </Typography>
                {dateDone && renderDeviation()}
              </Stack>
              <Stack direction='row' alignItems='center'>
                <Typography
                  variant='body2'
                  color={theme.palette.secondary.gray}
                  textAlign='start'
                  fontSize='12px'
                  lineHeight='100%'
                  whiteSpace='nowrap'
                  marginRight='12px'
                >
                  Статус устранения:
                </Typography>

                <EliminationStatusMarker markerColor={eliminationStatusesColor[remark.contractorStatus]} />
                <Typography variant='body2' width='100%' textAlign='start'>
                  {eliminationStatusRuByEn[remark.contractorStatus]}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Description variant='body2' color={theme.palette.text.dark}>
        {remark.description.custom || remark.description.defect?.title}
      </Description>

      <Stack>
        <Typography variant='body2' color={theme.palette.secondary.gray} textAlign='start' fontSize='12px'>
          Файлы: {!remark.media.length && '—'}
        </Typography>
        {remark.media.length ? (
          <Stack direction='row' spacing={1} marginTop={1} alignItems='center'>
            {remark.media.slice(0, displayedImages).map((file) =>
              isPdfFile(file.key) ? (
                <FileWrapper onClick={() => onViewerOpen(file)}>
                  <FileIcon />
                  <Typography variant='subtitle1'>PDF</Typography>
                </FileWrapper>
              ) : (
                <Image onClick={() => onViewerOpen(file)} src={file.variations?.small}></Image>
              ),
            )}
            {remark.media.length > displayedImages && (
              <Typography variant='body1'>+{remark.media.length - displayedImages}</Typography>
            )}
          </Stack>
        ) : null}
      </Stack>
    </RemarkItemWrapper>
  )
}
