import { ICallFull, TCallStatus, TCallStatusRu } from 'api/calls/types'
import {
  CALL_COMPLETE_STATUS_COLOR,
  CALL_CREATED_STATUS_COLOR,
  CALL_CANCELLED_STATUS_COLOR,
  CALL_SENT_STATUS_COLOR,
  INSPECTION_PLANNING_STATUS_COLOR,
  INSPECTION_PLANNED_STATUS_COLOR,
  INSPECTION_REJECTED_STATUS_COLOR,
  INSPECTION_COMPLETED_STATUS_COLOR,
} from 'utils/constants'
import { LegendColors } from './global'
import { UserRoles } from './user'
import { ShortUserInfoWithPosition } from 'api/prescriptions/types'
import { PublicUserProfile } from 'api/profile/types'

export const callStatuses: TCallStatus[] = ['CREATED', 'SENT', 'CANCELLED', 'COMPLETE']

export const callStatusRuByEn: Record<TCallStatus, TCallStatusRu> = {
  CREATED: 'Создан',
  SENT: 'Направлен',
  CANCELLED: 'Отменен',
  COMPLETE: 'Завершен',
}

export const callStatusColorByEn: Record<TCallStatus, LegendColors> = {
  CREATED: CALL_CREATED_STATUS_COLOR,
  SENT: CALL_SENT_STATUS_COLOR,
  CANCELLED: CALL_CANCELLED_STATUS_COLOR,
  COMPLETE: CALL_COMPLETE_STATUS_COLOR,
}

export type TInspectionStatus = 'PLANNING' | 'PLANNED' | 'REJECTED' | 'COMPLETED'
export type TInspectionStatusRu = 'К планированию' | 'Запланирован' | 'Отклонен' | 'Выполнен'
export const inspectionStatuses: TInspectionStatus[] = ['PLANNING', 'PLANNED', 'REJECTED', 'COMPLETED']

export const inspectionStatusRuByEn: Record<TInspectionStatus, TInspectionStatusRu> = {
  PLANNING: 'К планированию',
  PLANNED: 'Запланирован',
  REJECTED: 'Отклонен',
  COMPLETED: 'Выполнен',
}

export const inspectionStatusColorByEn: Record<TInspectionStatus, LegendColors> = {
  PLANNING: INSPECTION_PLANNING_STATUS_COLOR,
  PLANNED: INSPECTION_PLANNED_STATUS_COLOR,
  REJECTED: INSPECTION_REJECTED_STATUS_COLOR,
  COMPLETED: INSPECTION_COMPLETED_STATUS_COLOR,
}

export const getCallStatusesForMenu = (
  selectedStatus: TCallStatus,
  profile: PublicUserProfile,
  callData?: ICallFull | null,
) => {
  const { id: profileId, role, company } = profile
  const isAdmin: boolean = role === 'admin'
  const isEngineer: boolean = role === 'engineer_qc'
  const isResponsibleForInspection: boolean =
    callData?.inspection.responsible?.representative?.user === profileId ||
    callData?.inspection.responsible?.user?.id === profileId
  const { planDate, factDate } = callData?.inspection || {}
  const isAuthor = callData?.author.id === profileId
  const isSameCompany = isEngineer && company.userCompanyName === callData?.author.company.userCompanyName
  const isInitiator =
    callData?.responsibleUser.representative?.user === profileId || callData?.responsibleUser.user?.id === profileId

  return callStatuses.filter((status) => {
    if (status === selectedStatus) return false
    if (!isAdmin && !isResponsibleForInspection && status === 'COMPLETE') return false
    if (
      !isAdmin &&
      !isAuthor &&
      !isInitiator &&
      !isSameCompany &&
      (status === 'CREATED' || status === 'SENT' || status === 'CANCELLED')
    )
      return false
    if ((planDate || factDate) && status === 'CREATED') return false

    return true
  })
}
