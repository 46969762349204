import { UICProvider } from './contexts/ui'
import { i18n } from './i18n'
import './styles/App.scss'
import { StyledEngineProvider, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ArcElement, Chart } from 'chart.js'
import { AppRoutes } from 'core/router'
import ruLocale from 'date-fns/locale/ru'
import { SnackbarOrigin, SnackbarProvider } from 'notistack'
import { FC } from 'react'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { store } from 'store'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { theme } from 'styles/theme'

const snackbarAnchorOrigin: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}

Chart.register(ArcElement)

const App: FC = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <StyledThemeProvider theme={theme}>
            <I18nextProvider i18n={i18n}>
              <Provider store={store}>
                <UICProvider>
                  <BrowserRouter>
                    <SnackbarProvider
                      style={{ maxWidth: 320 }}
                      anchorOrigin={snackbarAnchorOrigin}
                      // maxSnack={1}
                      maxSnack={4}
                      // autoHideDuration={1500}
                    >
                      <div className='App'>
                        <AppRoutes />
                      </div>
                    </SnackbarProvider>
                  </BrowserRouter>
                </UICProvider>
              </Provider>
            </I18nextProvider>
          </StyledThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  )
}

export default App
