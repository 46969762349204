import { uiContext } from 'contexts/ui'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { FC, useContext, useLayoutEffect } from 'react'
import { SettingsContent, SettingsWrapper } from './AdminSettings.styles'
import { Typography } from '@mui/material'
import { theme } from 'styles/theme'
import { SettingsForm } from './components/SettingsForm'
import { SettingsInfo } from './components/SettingsInfo'

export const AdminSettings: FC = () => {
  const cUI = useContext(uiContext)

  useLayoutEffect(() => {
    cUI.setShowSearch(false)
    return () => {
      cUI.setShowSearch(true)
    }
  }, [])

  useBreadcrumbs([{ title: 'Настройки' }])

  return (
    <SettingsWrapper>
      <Typography
        lineHeight='normal'
        textAlign='start'
        fontSize={32}
        padding='25px 30px'
        color={theme.palette.text.secondary}
      >
        Настройки интеграции по API
      </Typography>

      <SettingsContent>
        <SettingsForm />

        <SettingsInfo />
      </SettingsContent>
    </SettingsWrapper>
  )
}
