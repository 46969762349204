import { NewAuditItem } from '../NewAuditItem'
import { AuditInnerInfo } from '../NewAuditItem/components/AuditInfoItem/AuditInfoItem.types'
import { AuditStatusGraduation } from '../NewAuditItem/components/AuditStatusGraduation'
import { AuditPrescriptionItemProps } from './AuditPrescriptionItem.types'
import { Person as PersonIcon, Info as InfoIcon, InsertDriveFile as FileIcon } from '@mui/icons-material'
import { PrescriptionAuditAction } from 'api/prescriptions/types'
import { prescriptionStatusRuByEn } from 'core/types/prescription'
import { userRolesEnToRu } from 'core/types/user'
import { FC } from 'react'
import { connectNames } from 'utils/connectNames'

export const AuditPrescriptionItem: FC<AuditPrescriptionItemProps> = ({ prescriptionAudit }) => {
  const {
    action,
    timestamp,
    user: userData,
    value: currentPresctiptionStatus,
    previous: previousPresctiptionStatus,
    mailRecipients,
    shortcoming,
  } = prescriptionAudit || {}
  const { role, user: user } = userData

  const userText = `${connectNames(user, true)}, ${userRolesEnToRu[role]}`

  const prescriptionAuditTitleByAction: Record<PrescriptionAuditAction, string> = {
    CREATE: 'Создано предписание',
    STATUS: 'Изменен статус',
  }

  const prescriptionInfoData: AuditInnerInfo[] = [
    { icon: <PersonIcon fontSize='medium' color='secondary' />, text: 'Автор', value: userText },
    {
      icon: <InfoIcon fontSize='medium' color='secondary' />,
      text: 'Статус',
      value: (
        <AuditStatusGraduation
          previous={prescriptionStatusRuByEn[previousPresctiptionStatus]}
          current={prescriptionStatusRuByEn[currentPresctiptionStatus]}
        />
      ),
    },
    {
      icon: <FileIcon fontSize='medium' color='secondary' />,
      text: 'Замечание',
      value: `№ ${shortcoming}`,
      hidden: !shortcoming,
    },
  ]

  return (
    <NewAuditItem
      auditData={{
        title: prescriptionAuditTitleByAction[action],
        timestamp,
        mailRecipients,
        info: prescriptionInfoData,
      }}
    />
  )
}
